var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxhealthytip" }, [
    _c("div", { staticClass: "title" }, [_vm._v("健康风险提示")]),
    _vm.result.includes("平和质") || _vm.result === "健康"
      ? _c("div", { staticClass: "none" }, [_vm._v("无")])
      : _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "left" },
            _vm._l(_vm.healthyRisk, function(item, index) {
              return _c("div", { key: index, staticClass: "risk" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/img/wx/wx_jbts_jcjg.png"),
                    alt: "症状图标"
                  }
                }),
                _c("span", [_vm._v(_vm._s(item.name))])
              ])
            }),
            0
          ),
          _c("img", {
            staticClass: "body",
            attrs: {
              src: require("../../../assets/img/wx/wx_tp_jcjg.png"),
              alt: "人体图"
            }
          })
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }