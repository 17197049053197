<template>
  <div class="wxhealthyheader">
    <div class="left">
      <h2>{{symptomString}}</h2>
      <span>{{tipString}}</span>
      <div class="bottom">
        <span>报告编号：{{displayId}}</span>
        <span>检测时间：{{time}}</span>
      </div>
    </div>
    <div class="right">
      <div>健康状态：</div>
      <span>{{healthStatus}}</span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    healthStatus: String,
    displayId: String,
    time: String,
    type: String,
    symptoms: Array
  },
  data(){
    return {
      tipString: null,
      symptomString: ''
    }
  },
  created(){
    if(this.type === "卵巢"){
      this.tipString = this.healthStatus === '健康' ? '您的卵巢功能正常，请继续保持健康的生活方式。' : '建议咨询专业人士进行健康调理。'
    } else {
      this.tipString = this.healthStatus === '健康' ? '您非常健康，请继续保持乐观的心态和健康的生活方式。' : '建议咨询专业人士进行健康调理。'
    }
    // 
    var that = this
    this.symptoms.forEach(function(element) {
      that.symptomString += element.name
    });
  }
}
</script>

<style lang="scss" scoped>
$headerbg: '../../../assets/img/wx/wx_bj_jcjg.png';
$rightbg: '../../../assets/img/wx/wx_jkzs_jcjg.png';

.wxhealthyheader {
  width: 100%;
  min-height: 540px;
  background:url($headerbg);
  background-size:cover;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding-bottom: 54px;
  .left {
    color: #fff;
    margin: -10px 0 0 45px;
    width: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
    h2 {
      font: {
        size: 52px;
        family: PingFangSC-Medium;
      };
      margin-top: 180px;
    }
    span {
      display: block;
      font-size: 36px;
      font-family: SourceHanSansCN-Regular;
      line-height: 54px;
    }
    .bottom {
      position: absolute;
      bottom: 40px;
    }
  }
  .right {
    margin-left: -10%;
    background-color: yellow;
    width: 60%;
    background:url($rightbg);
    background-size:cover;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      font-family: PingFangSC-Medium;
	    font-size: 38px;
      color: #ffffff;
      margin: 40px 0 0 60px;
    }
    span {
      font-family: PingFangSC-Medium;
	    font-size: 60px;
      color: #ffffff;
      margin: 40px 0 0 60px;
    }
  }
}
</style>