<template>
  <div class="wxhealthytip">
    <div class="title">健康风险提示</div>
    <!-- 体质辨识平和质   健康辨识 健康  女性健康健康  风险提示显示为无-->
    <div v-if="result.includes('平和质') || result === '健康' " class="none">无</div>
    <div v-else class="content">
      <div class="left">
        <div class="risk" v-for="(item, index) in healthyRisk" :key="index">
          <img src="../../../assets/img/wx/wx_jbts_jcjg.png" alt="症状图标"/>
          <span>{{item.name}}</span>
        </div>
      </div>
      <img class="body" src="../../../assets/img/wx/wx_tp_jcjg.png" alt="人体图"/>
    </div>
  </div>
</template>

<script>
import { queryObject } from '../../../../utils/common.js';
const { category = '' } = queryObject();

export default {
  props:{
    result: String, //健康辨识 体质辨识 女性健康 对应传参
    risk: Object, //健康辨识 体质辨识 女性健康 对应传参
    gender: Number, //健康辨识 对应传参
    symptoms: Array, //健康辨识 对应传参
    type: String, //女性健康 对应传参
    riskType: Array, // 女性健康 对应传参
  },
  data(){
    return {
      healthyRisk: null
    }
  },
  computed: {
    category(){
      return category
    }
  },
  beforeMount(){
   this.resetData()
  },
  methods: {
    resetData(){
      let jbfx = {}
      if(this.gender === 1) {
       jbfx = {
          脾虚: [{name:'食欲不振、消化不良、腹胀腹泻'}, {name:'内脏下垂'}, {name:'不规则出血（鼻衄、齿衄、崩漏、痔血、皮下瘀斑）'}],
          气虚: [{name:'感冒等上呼吸道感染疾病'}, {name:'慢性疲劳综合症'}, {name:'贫血'}, {name:'内脏下垂'}],
          肾虚: [{name:'齿摇、耳鸣'}, {name:'头晕'}, {name:'腰痛、腰肌劳损、腰椎间盘突出症'}, {name:'遗精、早泄'}],
          痰湿: [{name:'高血尿酸症、痛风'}, {name:'代谢综合症（三高）'}, {name:'腹胀'}, {name:'肥胖'}],
          阳虚: [{name:'听力下降'}, {name:'感冒等上呼吸道感染疾病'}, {name:'腹泻、五更泻'}, {name:'关节炎、老寒腿'}, {name:'生理学阳痿、早泄'}],
          阴虚: [{name:'耳鸣、听力下降'}, {name:'干燥综合症'}, {name:'情绪心理疾病'}, {name:'脱发、失眠'}, {name:'口腔溃疡、齿脱'}, {name:'便秘'}],
          郁滞: [{name:'游走性疼痛'}, {name:'情志病（梅核气、抑郁症）'}, {name:'胸痹（冠心病）'}]
        }
      } else {
        jbfx = {
          脾虚: [{name:'食欲不振、消化不良、腹胀腹泻'}, {name:'内脏下垂'}, {name:'不规则出血（鼻衄、齿衄、崩漏、痔血、皮下瘀斑）'}],
          气虚: [{name:'感冒等上呼吸道感染疾病'}, {name:'慢性疲劳综合症'}, {name:'贫血'}, {name:'内脏下垂'}],
          肾虚: [{name:'齿摇、耳鸣'}, {name:'头晕'}, {name:'腰痛、腰肌劳损、腰椎间盘突出症'}],
          痰湿: [{name:'高血尿酸症、痛风'}, {name:'代谢综合症（三高）'}, {name:'腹胀'}, {name:'肥胖'}],
          阳虚: [{name:'听力下降'}, {name:'感冒等上呼吸道感染疾病'}, {name:'腹泻、五更泻'}, {name:'关节炎、老寒腿'}, {name:'多囊卵巢综合症'}],
          阴虚: [{name:'耳鸣、听力下降'}, {name:'干燥综合症'}, {name:'情绪心理疾病'}, {name:'脱发、失眠'}, {name:'口腔溃疡、齿脱'}, {name:'便秘'}],
          郁滞: [{name:'游走性疼痛'}, {name:'情志病（梅核气、抑郁症）'}, {name:'胸痹（冠心病）'}]
        }
      }
      
      if(category === '2') { //如果是体质辨识
        const tipString = this.result.substring(0,3)
        this.healthyRisk = this.risk[tipString]
      } else { 
        if(this.type === 'female'){
          if(this.riskType.length > 0) {
            const index = this.riskType[0].name
            //女性健康
            this.healthyRisk = this.risk[index]
          }
        } else {
          //健康辨识
          if(this.symptoms.length > 0){
            const symptomName = this.symptoms[0].name
            this.healthyRisk = jbfx[symptomName]
          }
        }
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.wxhealthytip {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 30px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 360px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
  .none {
    font-size: 39px;
    color: #666;
  }
  .content {
    display: flex;
    flex-direction: row;
    .left {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: #666666;
      font-size: 39px;
      margin-left: 38px;
      .risk {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
      }
      img {
        width: 32px;
      }
      span {
        margin-left: 20px;
      }
    }
    .body {
      width: 438px;
      height: 657px;
    }
  }
}
</style>