var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxbloodpressure" }, [
    _c("div", { staticClass: "title" }, [_vm._v("血压结果")]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "number" }, [
          _vm._v(_vm._s(_vm.pulse[0].blood_dia))
        ]),
        _c("div", { staticClass: "text" }, [_vm._v("舒张压")]),
        _c("div", { staticClass: "unit" }, [_vm._v("mmHg")])
      ]),
      _c("div", { staticClass: "left right" }, [
        _c("div", { staticClass: "number" }, [
          _vm._v(_vm._s(_vm.pulse[0].blood_sys))
        ]),
        _c("div", { staticClass: "text" }, [_vm._v("收缩压")]),
        _c("div", { staticClass: "unit" }, [_vm._v("mmHg")])
      ])
    ]),
    _c("div", { staticClass: "tip-blood" }, [
      _vm._v(
        "提示：" +
          _vm._s(
            _vm.pulse[0].blood_dia < 60 || _vm.pulse[0].blood_sys < 90
              ? "有低血压风险"
              : _vm.pulse[0].blood_dia >= 60 &&
                _vm.pulse[0].blood_dia < 80 &&
                _vm.pulse[0].blood_sys >= 90 &&
                _vm.pulse[0].blood_sys < 120
              ? "正常血压"
              : _vm.pulse[0].blood_dia >= 80 &&
                _vm.pulse[0].blood_dia < 90 &&
                _vm.pulse[0].blood_sys >= 90 &&
                _vm.pulse[0].blood_sys < 140
              ? "正常高值"
              : _vm.pulse[0].blood_dia >= 60 &&
                _vm.pulse[0].blood_dia < 90 &&
                _vm.pulse[0].blood_sys >= 120 &&
                _vm.pulse[0].blood_sys < 140
              ? "正常高值"
              : _vm.pulse[0].blood_dia >= 90 || _vm.pulse[0].blood_sys >= 140
              ? "有高血压风险"
              : ""
          )
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }