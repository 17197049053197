<template>
  <div v-if="reportData" class="wxfemale">
    <wxfemaleheader
    :type="reportData.type_attention"
    :healthStatus="reportData.health_status"
    :displayId="reportData.display_id"
    :time="reportData.time"
    :symptoms="reportData.symptoms"
    ></wxfemaleheader>
    <div class="content">
      <!-- 个人信息 -->
      <wxinfo type="female" :info="reportData.owner" :age="reportData.user_info.ages"></wxinfo>
      <!-- 中医辩证分型 -->
      <wxdialecticalchart
      :symptoms="reportData.symptoms"
      :symptomStatus="reportData.symptom_status"
      :beforeSymptomStatus="reportData.before_symptom_status.before_symptom_status"
      ></wxdialecticalchart>
      <!-- 健康解读 -->
      <wxexplan
      type="female"
      :symptoms="reportData.symptoms"
      :symptomDefinition="reportData.solutions.symptom_definition"
      :healthRisk="reportData.solutions.health_risk"
      ></wxexplan>
      <!-- 健康风险提示 -->
      <wxhealthytip
      type="female"
      :risk="reportData.solutions.disease_menopause_ovary"
      :result="reportData.health_status"
      :riskType="reportData.symptoms" 
      ></wxhealthytip>
      <!--  面诊结果 -->
      <wxfaceresult :faceData="reportData.face_result" :facePhotoUrl="reportData.face_photo_url"></wxfaceresult>
      <!-- 舌诊结果 -->
      <wxtongueresult :tongueData="reportData.tongue_result" :tonguePhotoUrl="reportData.tongue_photo_url"></wxtongueresult>
      <!-- 健康方案 -->
      <wxfemalescheme :reportData="reportData"></wxfemalescheme>
      <!--  -->
      <div class="tip">此报告仅作参考，如有不适请及时就医</div>
      <!--  推荐医生 -->
      <wxdoctorlist v-if="reportData.doctor.length !== 0" :doctorData="reportData.doctor"></wxdoctorlist>
    </div>
  </div>
</template>

<script>
import { getMenopauseOvary } from '@/api/report.js';
import wxfemaleheader from './components/wxfemaleheader.vue';
import wxinfo from './components/wxinfo.vue';
import wxexplan from './components/wxexplan.vue';
import wxhealthytip from './components/wxhealthytip.vue';
import wxfaceresult from './components/wxfaceresult.vue';
import wxtongueresult from './components/wxtongueresult.vue';
import wxdialecticalchart from './components/wxdialecticalchart.vue';
import wxfemalescheme from './components/wxfemalescheme.vue';
import Wxdoctorlist from './components/wxdoctorlist.vue';

export default {
  components: { wxfemaleheader, wxinfo, wxexplan, wxhealthytip, wxfaceresult, wxtongueresult, wxdialecticalchart, wxfemalescheme, Wxdoctorlist },
  data(){
    return {
      reportData: null, 
    }
  },
  created(){
    this.getData();
  },
  methods:{
    getData(){
      getMenopauseOvary({
        id: this.$route.params.id
      }).then(res => {
        if(res && res.status_code === 10000 && res.data) {
          // console.log(res.data)
          this.reportData = res.data;
          res.data.type_attention === '卵巢' ? document.title = '卵巢功能评估' : document.title = '女性更年期评估'
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wxfemale {
  width: 100%;
  background-color: #f5f5f5;
  .content {
    margin: -55px 45px 45px 45px;
  }
  .tip {
    width: 100%;
    text-align: center;
    font-family: MicrosoftYaHei;
	  font-size: 36px;
    color: #cccccc;
    padding: 42px 0;
  }
}
</style>