import axios from 'axios'
import Qs from 'qs'
import store from '../store'
import { Toast } from 'vant';

const $axios = axios.create({
    // 设置超时时间
    timeout: 100000,
    // 基础url，会在请求url中自动添加前置链接
    baseURL: process.env.VUE_APP_BASE_API
})

// 在全局请求和响应拦截器中添加请求状态
// let loading = null

// 请求拦截器
$axios.interceptors.request.use(
    config => {
      // Toast.loading({
      //   message: '努力加载中',
      //   forbidClick: true,
      // });
      store.commit('SET_LOADING', true) 
      return config;
    },
    error => {
      return Promise.reject(error)
    }
)

// 响应拦截器
$axios.interceptors.response.use(
    response => {
      // Toast.clear();
      store.commit('SET_LOADING', false) 
      const code = response.status
      if ((code >= 200 && code < 300) || code === 304) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response)
      }
    },
    error => {
      // Toast.clear();
      store.commit('SET_LOADING', false) 
      if (error.response) {
        switch (error.response.status) {
          case 401:
            break
          case 404:
            this.$toast('网络请求不存在')
            break
          default:
            Toast(error.response.data.message)
        }
      } else {
        // Toast.clear();
        store.commit('SET_LOADING', false) 
        // 请求超时或者网络有问题
        if (error.message.includes('timeout')) {
          Toast('请求超时！请检查网络是否正常')
        } else {
          Toast('请求失败，请检查网络是否已连接')
        }
      }
      return Promise.reject(error)
    }
  )
  
  export default {
    post(url, data) {
      return $axios({
        method: 'post',
        url,
        data,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        }
      })
    },
    patch(url, data){
      return $axios({
        method: 'patch',
        url,
        data,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        }
      })
    },
    put(url, data){
      return $axios({
        method: 'put',
        url,
        data: Qs.stringify(data),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }
      })
    },
    get(url, params) {
      return $axios({
        method: 'get',
        url,
        params
      })
    },
    delete(url, params) {
      return $axios({
        method: 'delete',
        url,
        params
      })
    },
    files(url, formData){
      return $axios({
        method: 'post',
        url,
        data: formData,
        headers: {
          'Content-Type': 'application/form-data; charset=UTF-8'
        }
      })
    }
  }
  
