<template>
  <div class="wxproduct">
    <div class="title">特别推荐</div>
    <van-tabs v-model="active" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333" @click="scrollTop()">
      <van-tab v-for="(item, index) in productData" :key="index" :title="item.name">
        <div class="product" :ref="'productBox' + index">
          <div v-for="(info, index) in item.products"
          :key="index" class="productItem" @click="gotoDetail(info)">
            <div class="imgBox">
              <img :src="info.image"/>
            </div>
            <div class="desc">{{info.name}}</div>
            <div class="price">{{info.price? '￥'+ info.price : '-'}}</div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  import { recordClick } from '../../../api/report'

export default {
  props: {
    productData: Array
  },
  data(){
    return {
      active: 0,
    }
  },
  methods: {
    gotoDetail(item){
      // 埋点（微信-手机报告上，点击进入到产品详情）
      const params = {
        product_id: item.id,    //  产品id
        count_type: "wechat",  // 从镜子端过来的就是mirror  微信端过来的是wechat
      };
      recordClick(params).then(res =>{
        if(item.market_url) {
          window.location = item.market_url;
        }
      }).catch(e => {
        alert(e)
      }); // 记录一下用户的点击事件

    },
    scrollTop(){
      // 进度条拉到最上面
      const current = 'productBox' + this.active;
      // this.$refs[current][0].scrollTop = 0;
      this.$nextTick(() => {
        this.$refs[current][0].scrollTop = 0;
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.wxproduct {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 30px 0;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    margin: 0 0 10px 51px;
    border-left: 6px solid #1288eb;
    padding-left: 30px;
  }
  .product {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    max-height: 1120px;
    overflow-y: auto;
    .productItem {
      width: 44%;
      margin: 0 0 45px 45px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .imgBox {
        width: 100%;
        height: 400px;
        overflow: hidden;
        border: 1px solid #dcdcdc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: auto;
          height: 350px;
          display: block;
        }
      }

      .desc{
        font-family: PingFangSC-Regular;
	      font-size: 39px;
        color: #333333;
        line-height: 40px;
        margin-top: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow:hidden;
      }

      .price {
        font-family: PingFangSC-Regular;
        font-size: 40px;
        color: #30c3fa;
        margin-top: 20px;
      }
    }
  }
}
</style>

<style>
  .wxproduct .van-tab {
    font-size: 45px;
    color: #333;
    line-height: 88px;
  }

  .wxproduct .van-tabs--line .van-tabs__wrap {
    height: 88px;
  }
  
  .wxproduct .van-tab__text--ellipsis {
    flex: none !important;
  }

  .wxproduct .van-tabs__line {
    width: 120px;
  }

  .wxproduct .van-tabs__content {
    margin-top: 50px;
  }
</style>