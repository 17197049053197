<template>
  <div v-if="productval">
    <div class="wares" ref="top">
      <div class="title">
        <span @click="back"><&nbsp;返回</span>
        <h1>特别推荐</h1>
      </div>
      <div class="proDetail">
        <img :src="productval.image" alt="大图" />
        <div class="price" :style="(this.productval.qr_code || this.productval.market_url)?{ width: '75%' } : { width: '90%' }">
          <div style="overflow: auto;margin: 0">
            <span class="boxname">{{productval.name? productval.name : '未知'}}</span>
            <span class="boxprice">{{productval.price? `&yen;${productval.price} ` : ''}}</span>
<!--            <div style="float: right">-->
<!--              <img :src="this.productval.qr_code_url" width="50" height="50">-->
<!--            </div>-->
          </div>
          <span class="boxdesc">{{productval.desc? productval.desc : '暂无相关描述'}}</span>
        </div>
        <div class="buySrc">
          <img v-if="this.productval.qr_code" :src="this.productval.qr_code" ><br/>
<!--          <a v-if="this.productval.market_url" class="buysty" :href="this.productval.market_url" target="_blank">点击购买</a>-->
        </div>
        <img :src="item.image" v-for="(item , index) in productval.photos" :key="index" class="selfImg">
      </div>
      <footer>
        注：特别推荐所展示的商品/服务的标题、价格、详情等信息内容系由合作方发布，其真实性、准确性和合法性由信息拥有者（合作方）负责。本站不提供任何保证，并不承担任何法律责任。
      </footer>
      <button v-if="marker !=='10.1'"  @click="backTop" class="toTop" title="回顶部">返回顶部</button>
    </div>

  </div>

</template>

<script>
  import { queryObject } from '../../../utils/common.js';
  const { access = '', csr = '',marker = '' } = queryObject();

  export default {
    name: "wares",
    data(){
      return{
        productval:'',
        img:'',
      }
    },
    methods:{
      back(){
        // this.$router.back();
        // 关闭详情页
        this.$store.commit('SET_DETAIL_SHOW',false); // 展示详情页
      },

      backTop(){
        this.$refs.top.scrollTop = 0;
      }

    },
    mounted() {
      window.addEventListener("scroll",this.showBtn,true);
    },
    beforeMount(){
      this.productval = this.$store.state.current_product
    },
    computed:{
      marker(){
        return marker
      }
    }
  }
</script>

<style scoped>
  .wares{
    height:100%;
    width:100%;
    overflow:auto;
    background-color: #fff;
    position:fixed;
    top:0px;
    left:0px;
  }

  .title {
    margin-top: 100px;
    position: relative;
  }

  .title>span {
    font-size: 36px;
    color: #666666;
    font-weight: bold;
    position: absolute;
    left: 40px;
    top: 20px
  }

  .title>h1{
    font-size: 60px;
    color: #333333;
    font-family: TITLE;
    text-align: center
  }


  .proDetail {
    margin-bottom: 50px;
  }

  .proDetail>img {
    width: 100%;
    height: auto;
    display: block;
  }

  .proDetail>img.selfImg {
    margin-top: 30px
  }

  .buySrc {
    float: right;
    display: inline-block;
    margin-right:70px;
    width: 150px;
    padding-top: 20px
  }

  .buySrc>img {
    width: 120px;
    height: 120px;
    margin-left: 15px;
  }

  .boxname{
    width: 60%;
    font-size: 36px;
    color: #333333;
    font-weight: bold;
    display: block;
    float: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .boxprice{
    width: 40%;
    font-size: 30px;
    color: #00adfe;
    font-weight: bold;
    display: block;
    float: left;
    text-align:right;
  }
  .boxdesc{
    color: #666666;
    font-size: 32px;
    line-height: 50px;
    margin: 0;
    overflow: hidden;
    height: 100px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .price{
    padding: 20px 10px;
    width: 75%;
    margin-left:20px;
    display: inline-block
  }
  .buysty{
    text-align: center;
    display: inline-block;
    width: 150px;
    height: 40px;
    color: black;
    line-height: 40px;
    font-size: 25px;
    border: 1px black solid;
    border-radius: 20px;
    margin-top: 10px;
  }
  .toTop{
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #b8b6b6;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
  }

  footer {
    background-color: #f5f5f5;
    font-size: 36px;
    color: #999999;
    padding: 30px 5%;
    line-height: 52px
  }

</style>
