var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "toptzbs", staticClass: "product-detail" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("van-icon", {
          attrs: { name: "arrow-left", size: "40px", color: "#333" },
          on: { click: _vm.goback }
        }),
        _c("span", { staticClass: "one", on: { click: _vm.goback } }, [
          _vm._v("返回")
        ]),
        _c("span", { staticClass: "two" }, [_vm._v("特别推荐")])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("img", { attrs: { src: _vm.itemDate.image } }),
        _c("div", { staticClass: "both" }, [
          _c(
            "div",
            {
              class:
                _vm.itemDate.qr_code && _vm.itemDate.market_url
                  ? "left"
                  : "left left-two"
            },
            [
              _c("div", { staticClass: "product" }, [
                _c("span", { staticClass: "text1" }, [
                  _vm._v(_vm._s(_vm.itemDate.name))
                ]),
                _c("span", { staticClass: "text2" }, [
                  _vm._v(
                    _vm._s(_vm.itemDate.price ? "¥" + _vm.itemDate.price : "")
                  )
                ])
              ]),
              _c("span", { staticClass: "text3" }, [
                _vm._v(_vm._s(_vm.itemDate.desc))
              ])
            ]
          ),
          _vm.itemDate.market_url
            ? _c(
                "div",
                { staticClass: "right" },
                [
                  _vm.itemDate.market_url
                    ? _c("qrcode-vue", {
                        attrs: { value: _vm.itemDate.market_url, size: "120" }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._l(_vm.itemDate.photos, function(item, id) {
          return _c("div", { key: id, staticClass: "img" }, [
            _c("img", { attrs: { src: item.image, alt: "产品介绍" } })
          ])
        })
      ],
      2
    ),
    _c("div", { staticClass: "footer" }, [
      _vm._v(
        " 注：特别推荐所展示的商品/服务的标题、价格、详情等信息内容系由合作方发布，其真实性、准确性和合法性由信息拥有者（合作方）负责。本站不提供任何保证，并不承担任何法律责任。 "
      )
    ]),
    _vm.marker !== "10.1"
      ? _c(
          "button",
          {
            staticClass: "toTop",
            attrs: { title: "回顶部" },
            on: { click: _vm.topFunction }
          },
          [_vm._v("返回顶部")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }