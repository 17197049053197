var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wxexplan" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("健康解读")]),
      _vm.symptoms.length === 0 || _vm.symptoms[0].name.includes("健康")
        ? _c("div", { staticClass: "none" }, [_vm._v("无")])
        : _c(
            "van-tabs",
            {
              attrs: {
                swipeable: "",
                "title-active-color": "#30c3fa",
                color: "#30c3fa",
                "title-inactive-color": "#333333"
              },
              model: {
                value: _vm.active,
                callback: function($$v) {
                  _vm.active = $$v
                },
                expression: "active"
              }
            },
            _vm._l(_vm.symptomsData, function(item, index) {
              return _c(
                "van-tab",
                { key: index, attrs: { title: item.name } },
                [
                  _vm.type !== "chronic"
                    ? _c(
                        "div",
                        [
                          _c("p", { staticClass: "healthBox top" }, [
                            _c("img", {
                              staticClass: "circleBg",
                              attrs: {
                                src: require("../../../assets/img/wx//wx_dian_jcjg.png"),
                                alt: "分界线"
                              }
                            }),
                            _vm._v("定义解释")
                          ]),
                          _c("div", { staticClass: "healthContent" }, [
                            _vm._v(
                              _vm._s(_vm.type === "female" ? "" : item.name) +
                                _vm._s(item.content[0])
                            )
                          ]),
                          _c("p", { staticClass: "healthBox" }, [
                            _c("img", {
                              staticClass: "circleBg",
                              attrs: {
                                src: require("../../../assets/img/wx//wx_dian_jcjg.png"),
                                alt: "分界线"
                              }
                            }),
                            _vm._v("健康提示")
                          ]),
                          _c("div", { staticClass: "healthContent" }, [
                            _vm._v(
                              _vm._s(_vm.type === "female" ? "" : item.name) +
                                _vm._s(_vm.healthRisk[item.name].content[0])
                            )
                          ]),
                          _c("p", { staticClass: "healthBox" }, [
                            _c("img", {
                              staticClass: "circleBg",
                              attrs: {
                                src: require("../../../assets/img/wx//wx_dian_jcjg.png"),
                                alt: "分界线"
                              }
                            }),
                            _vm._v("可能原因")
                          ]),
                          _vm._l(item.content, function(info, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "healthContent2" },
                              [
                                i !== 0
                                  ? _c(
                                      "div",
                                      { class: i === 2 ? "content-top" : "" },
                                      [_vm._v(_vm._s(info))]
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _c("div", [
                        _c("p", { staticClass: "healthBox top" }, [
                          _c("img", {
                            staticClass: "circleBg",
                            attrs: {
                              src: require("../../../assets/img/wx//wx_dian_jcjg.png"),
                              alt: "分界线"
                            }
                          }),
                          _vm._v("中医解释")
                        ]),
                        _c("div", { staticClass: "healthContent" }, [
                          _vm._v(_vm._s(item.content[0]))
                        ]),
                        _c("p", { staticClass: "healthBox" }, [
                          _c("img", {
                            staticClass: "circleBg",
                            attrs: {
                              src: require("../../../assets/img/wx//wx_dian_jcjg.png"),
                              alt: "分界线"
                            }
                          }),
                          _vm._v("可能原因")
                        ]),
                        _c("div", { staticClass: "healthContent2" }, [
                          _vm._v(_vm._s(item.content[1]))
                        ])
                      ])
                ]
              )
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }