var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "headerTabOne" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "left", style: _vm.styleDiff }, [
        _vm._m(0),
        _c("span", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.goodfood.content[0]))
        ])
      ]),
      _vm.badfood.content.length > 0
        ? _c("div", { staticClass: "left right" }, [
            _vm._m(1),
            _c("span", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.badfood.content[0] || "暂无"))
            ])
          ])
        : _vm._e()
    ]),
    _vm.solutions
      ? _c("div", { staticClass: "Divys" }, [
          _c(
            "div",
            { style: { marginBottom: "50px" } },
            _vm._l(_vm.ysty, function(item) {
              return _c(
                "div",
                { key: item.name },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.name))
                  ]),
                  _c("img", {
                    staticClass: "image",
                    attrs: {
                      src: "" + _vm.solutions.asset_prefix + item.asset[0]
                    }
                  }),
                  _c("span", { staticClass: "title2" }, [_vm._v("烹煮方法：")]),
                  _vm._l(item.content, function(step, index) {
                    return _c("span", { key: index, staticClass: "title3" }, [
                      _vm._v(_vm._s(step))
                    ])
                  })
                ],
                2
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-top" }, [
      _c("img", {
        attrs: { src: require("../../assets/img/report/ys_jkfa_icon.png") }
      }),
      _c("span", [_vm._v("宜食")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-top" }, [
      _c("img", {
        attrs: { src: require("../../assets/img/common/js_jkfa_icon.png") }
      }),
      _c("span", [_vm._v("忌食")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }