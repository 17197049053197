import { render, staticRenderFns } from "./headertabone.vue?vue&type=template&id=755a3217&scoped=true&"
import script from "./headertabone.vue?vue&type=script&lang=js&"
export * from "./headertabone.vue?vue&type=script&lang=js&"
import style0 from "./headertabone.vue?vue&type=style&index=0&id=755a3217&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755a3217",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\zy_frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('755a3217')) {
      api.createRecord('755a3217', component.options)
    } else {
      api.reload('755a3217', component.options)
    }
    module.hot.accept("./headertabone.vue?vue&type=template&id=755a3217&scoped=true&", function () {
      api.rerender('755a3217', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/chronic/headertabone.vue"
export default component.exports