<template>
  <div class="wxexplan">
    <div class="title">健康解读</div>
    <!-- 慢病类型返回的数据不一致  添加 symptoms[0].name.includes('健康')" 做为健康判断 -->
    <div v-if="symptoms.length === 0 || symptoms[0].name.includes('健康')" class="none">无</div>
    <van-tabs v-else v-model="active" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
      <van-tab v-for="(item, index) in symptomsData" :key="index" :title="item.name">
        <!-- 健康 、 更年期、卵巢 -->
        <div v-if="type !== 'chronic'">
          <p class="healthBox top"><img src="../../../assets/img/wx//wx_dian_jcjg.png" alt="分界线" class="circleBg" />定义解释</p>
          <div class="healthContent">{{type === 'female' ? '' : item.name}}{{item.content[0]}}</div>
          <p class="healthBox"><img src="../../../assets/img/wx//wx_dian_jcjg.png" alt="分界线" class="circleBg" />健康提示</p>
          <div class="healthContent">{{type === 'female' ? '' : item.name}}{{healthRisk[item.name].content[0]}}</div>
          <p class="healthBox"><img src="../../../assets/img/wx//wx_dian_jcjg.png" alt="分界线" class="circleBg" />可能原因</p>
          <div class="healthContent2" v-for="(info, i) in item.content" :key="i">
            <div :class="i === 2 ? 'content-top' : ''" v-if="i !== 0">{{info}}</div>
          </div>
        </div>
        <!-- 高血压、糖尿病 -->
        <div v-else>
          <p class="healthBox top"><img src="../../../assets/img/wx//wx_dian_jcjg.png" alt="分界线" class="circleBg" />中医解释</p>
          <div class="healthContent">{{item.content[0]}}</div>
          <p class="healthBox"><img src="../../../assets/img/wx//wx_dian_jcjg.png" alt="分界线" class="circleBg" />可能原因</p>
          <div class="healthContent2">{{item.content[1]}}</div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  props: {
    symptoms: Array,
    symptomDefinition: Object,
    healthRisk: Object,
    type: String, //区别女性更年期和卵巢报告
  },
  data(){
    return {
      active: 0,
      symptomsData:[],
    }
  },
  beforeMount(){
    var that = this
    if(that.symptomDefinition != undefined) { // 慢病没有数据
      this.symptoms.forEach(function (value,index) {
        const name = value.name
        that.symptomsData.push(that.symptomDefinition[name])
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.wxexplan {
  background-color: #fff;
	border-radius: 30px;
  padding: 60px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }

  .none {
    font-size: 39px;
    color: #666;
  }

  .top {
    margin-top: 30px;
  }

  .circleBg {
    height: 32px;
    margin-left: -12px;
    margin-right: 30px;
  }

  .healthBox {
    font-size: 42px;
    font-family: PingFangSC-Regular;
    color: #333333;
  }

  .healthContent {
    padding: 20px 0 36px 42px;
    border-left: 6px dashed #72bfff;
    font-family: PingFangSC-Regular;
	  font-size: 39px;
    color:#666666;
    line-height: 60px;
  }

  .healthContent2 {
    padding: 0px 0 0px 42px;
    font-family: PingFangSC-Regular;
	  font-size: 39px;
    color:#666666;
    line-height: 60px;
  }

  .content-top {
    margin-top: 20px;
  }
}
</style>

<style>
  .wxexplan .van-tab {
    font-size: 45px;
    color: #333;
    line-height: 88px;
  }

  .wxexplan .van-tabs--line .van-tabs__wrap {
    height: 88px;
  }

  .wxexplan .van-tabs__line {
    width: 120px;
  }
</style>
