<template>
  <div class="wxbloodpressure">
    <div class="title">血压结果</div>
    <div class="content">
      <div class="left">
        <div class="number">{{pulse[0].blood_dia}}</div>
        <div class="text">舒张压</div>
        <div class="unit">mmHg</div>
      </div>
      <div class="left right">
        <div class="number">{{pulse[0].blood_sys}}</div>
        <div class="text">收缩压</div>
        <div class="unit">mmHg</div>
      </div>
    </div>
    <!-- 
      血压提示：
      1、舒张压小于60，或者收缩压小于90，则为“有低血压风险”
      2、舒张压大于等于60，小于80，并且收缩压大于等于90，小于120，则为“正常血压”
      3、舒张压大于等于80，小于90，并且收缩压大于等于90，小于140，则为“正常高值”。
      4、舒张压大于等于60，小于90，并且收缩压大于等于120，小于140，则为“正常高值”。
      5、舒张压大于等于90，或者收缩压大于等于140，则为“有高血压风险”
     -->
    <div class="tip-blood">提示：{{pulse[0].blood_dia < 60 || pulse[0].blood_sys < 90 ? '有低血压风险' :
     (pulse[0].blood_dia >= 60 && pulse[0].blood_dia < 80 && pulse[0].blood_sys >= 90 && pulse[0].blood_sys < 120) ? '正常血压' :
     (pulse[0].blood_dia >= 80 && pulse[0].blood_dia < 90 && pulse[0].blood_sys >= 90 && pulse[0].blood_sys < 140) ? '正常高值' :
     (pulse[0].blood_dia >= 60 && pulse[0].blood_dia < 90 && pulse[0].blood_sys >= 120 && pulse[0].blood_sys < 140) ? '正常高值' :
     (pulse[0].blood_dia >= 90 || pulse[0].blood_sys >= 140) ? '有高血压风险' : ''}}</div>
  </div>
</template>

<script>
export default {
  name: 'wxbloodpressure',
  props: {
    pulse: Array
  },
  data(){
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.wxbloodpressure {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 44px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
  .content {
    display: flex;
    flex-direction: row;
    .left {
      width: 50%;
    }
    .common {
      width: 100%;
      text-align: center;
      font-family: PingFangSC-Regular;
    }
    .number {
      font-family: DINAlternate-Bold;
      font-size: 72px;
      color: #069eff;
      @extend .common;
    }
    .text {
      font-size: 42px;
      color: #333333;
      @extend .common;
      margin: 30px 0 20px 0;
    }
    .unit {
      font-size: 33px;
      color: #999; 
      @extend .common;
    }
    .right {
      border-left: solid 6px #cccccc;
    }
  }
  .tip-blood {
    font-family: PingFangSC-Regular;
    font-size: 42px;
    color: #666666;
    margin-top: 45px;
  }
}
</style>