<template>
  <div class="content-bg">
    <!--  -->
    <div class="header">
      <div class="back-btn" @click="goBack">返&nbsp;回</div>
      <div class="header-title">养生方案</div>
    </div>
    <!-- header -->
    <header-tab :buttonType=buttonType @changed="changedIndex"></header-tab>
    <!-- 饮食调养 -->
    <header-tab-one v-if="currentIndex === 0" :isChronic="isChronic"></header-tab-one>
    <!-- 茶饮养生 -->
    <header-tab-two v-if="currentIndex === 1"
                    :SymptomsName="symptomsName"
                    :TextContent="tea"
    ></header-tab-two>
    <!--  经典药方 -->
    <header-tab-two v-if="currentIndex === 2" filedName="PrescriptionContent"
                    :PrescriptionContent="yf"
    ></header-tab-two>
    <!-- 预防保健操 -->
    <header-tab-two v-if="currentIndex === 3" :exercises="exercises"></header-tab-two>
<!--      穴位按压 -->
      <header-tab-two v-if="currentIndex === 4"
      :AcupointContent="xw"
      ></header-tab-two>
<!--    艾灸-->
    <header-tab-two v-if="currentIndex === 5" :moxibustion="moxibustion"></header-tab-two>
    <!-- 足浴疗法 6 -->
    <header-tab-two v-if="currentIndex === 6" :footBath="footBath"></header-tab-two>
    <!-- 精神调养 7 -->
    <header-tab-two v-if="currentIndex === 7"
                    TitleName="精神调养"
                    :Detail="mental"
    ></header-tab-two>
    <!--  运动保健 -->
    <header-tab-two v-if="currentIndex === 8"
                    TitleName="运动保健"
                    :Detail="sportHealth"
    ></header-tab-two>
    <header-tab-two v-if="currentIndex === 9"
                    :isProduct="true"
    ></header-tab-two>
  </div>
</template>

<script>
  import HeaderTab from '@/components/physical/headertab.vue';
  import HeaderTabOne from '@/components/chronic/headertabone.vue';
  import HeaderTabTwo from '@/components/chronic/headertabtwo.vue';

  import ysyj2 from '../../../assets/img/common/ysty2_ysfa_icon.png';
  import cyys2 from '../../../assets/img/common/cyys2_jkfa_icon.png';
  import xn from '../../../assets/img/common/xn_jkfa_icon.png';
  import xway2 from '../../../assets/img/common/xway2_ysfa_icon.png';
  import jdyf2 from '../../../assets/img/common/jdyf2_ysfa_icon.png';
  import aj from '../../../assets/img/chronic/aj_jkfa_icon.png';
  import zylf from '../../../assets/img/chronic/zylf_jkfa_icon.png';
  import bjyz2 from '../../../assets/img/common/bjyz2_ysfa_icon.png';
  import QZTC from '../../../assets/img/common/QZTC.png';
  import YDBJ from '../../../assets/img/common/YDBJ.png';
  import TBTJ from '../../../assets/img/common/tbtj_jkfa_icon.png';

  export default {
    components: {
      HeaderTab,
      HeaderTabOne,
      HeaderTabTwo,
    },
    data() {
      return {
        reportData: this.$store.state.report,
        isChronic: true, // 是否是慢病
        currentIndex: 0,
        buttonType: [], // 养生方案模块
        buttonTypeDic: [
          { title: '饮食调养', img: ysyj2 ,id:0 },
          { title: '茶饮养生', img: cyys2 ,id:1 },
          { title: '经典药方', img: jdyf2 ,id:2 },
          { title: '预防保健操', img: bjyz2 ,id:3 },
          { title: '穴位按压', img: xway2 ,id:4 },
          { title: '艾灸', img: aj ,id:5 },
          { title: '足浴疗法', img: zylf ,id:6 },
          { title: '精神调养', img: QZTC ,id:7 },
          { title: '运动保健', img: YDBJ ,id:8 },
          { title: '特别推荐', img: TBTJ ,id:9 },
        ],
        symptomsName: null,
        tea: null,
        teaImg: null,

        xnContent: null, // 香囊数据

        xw: null,
        xwVoide: null,
        xwVoideImg:null,

        factorDetail: null,

        dailyDetail: null,
        sportDetail: null,

        yf:null,
        exercises: null,
        moxibustion: null,  // 艾灸
        footBath: null, //足浴疗法
        mental: null, //精神调养
        sportHealth: null, //运动健身
        isShowCPTJ: this.$store.state.report.vendor_setting.product_advice_show === 1 &&
          ((this.$store.state.report.product && this.$store.state.report.product.length > 0) || (this.$store.state.report.doctor && this.$store.state.report.doctor.length > 0))
      }
    },
    beforeMount(){
      let indexArr = [];
      if(this.reportData.type_attention === '高血压'){
        indexArr = [0, 1, 4, 6, 7, 8];
      } else {
        if(this.reportData.classic_disease.length === 0) { // 糖尿病 健康状态有些没有经典药方
          indexArr = [0, 1, 3, 4, 5, 6, 7];
        } else {
          indexArr = [0, 1, 2, 3, 4, 5, 6, 7];
        }
      }
      if(this.isShowCPTJ) { // 产品推荐(放在第一个位置)
        indexArr.unshift(9);
      }
      for(let val of indexArr) { // 从字典里面找对应的标题项
        this.buttonType.push(this.buttonTypeDic[val]);
      }

      const symptoms = this.$store.state.report.symptoms;
      const solutions = this.$store.state.report.solutions;
      this.symptomsName = symptoms[0].name;


      this.tea = solutions.chronic_disease_tea[this.symptomsName];
      // this.teaImg = `${solutions.asset_prefix}${this.tea.asset}`
      // // 香囊
      // this.xnContent = solutions.tizhi_perfume_bag[this.symptomsName] // 香囊
      //
      // 穴位按压
      this.xw = solutions.chronic_disease_massage[this.symptomsName]
      // // 精神调养
      // this.factorDetail = solutions.tizhi_modern_factors[this.symptomsName]
      //
      // this.dailyDetail = solutions.tizhi_daily_life[this.symptomsName]
      //
      // this.sportDetail = solutions.tizhi_sport_health[this.symptomsName]

      // 经典药方
      this.yf = this.reportData.classic_disease;

      // 保健操
      this.exercises = solutions.chronic_disease_exercises && solutions.chronic_disease_exercises[this.symptomsName]

      // 艾灸
      this.moxibustion = solutions.chronic_disease_moxibustion && solutions.chronic_disease_moxibustion[this.symptomsName]

      // 足浴疗法
      this.footBath = solutions.chronic_disease_foot_bath && solutions.chronic_disease_foot_bath[this.symptomsName]

      // 精神调养
      this.mental = solutions.chronic_disease_mental_recuperation && solutions.chronic_disease_mental_recuperation[this.symptomsName]
      // 运动健身
      this.sportHealth = this.reportData.type_attention === '高血压' ? solutions.chronic_disease_sport_health[this.symptomsName] : null

    },
    methods: {
      goBack(){
        this.$router.back()
      },
      changedIndex(index,e){
        this.currentIndex = index
      }
    },
  }
</script>

<style lang="scss" scoped>
  $bgImg: '../../../assets/img/common/bj.png';
  $backBtn: '../../../assets/img/physical/fh_ty_icon.png';
  $headerTitle: '../../../assets/img/report/btbj_icon.png';

  .content-bg {
    width:100%;
    min-height: 100%;
    background:url($bgImg);
    background-size:cover;
    overflow: hidden;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin-top: 90px;
    margin-left: 5%;
    .back-btn {
      background:url($backBtn) no-repeat;
      background-size:100% 100%;
      width: 152px;
      height: 82px;
      color:#fff;
      font-size:36px;
      text-align:center;
      line-height:75px;
    }
    .header-title {
      font-size: 60px;
      color:#00fefb;
      font-family:TITLE;
      width:330px;
      height:120px;
      margin-left: 18%;
      background:url($headerTitle) no-repeat;
      text-align:center;
      line-height:120px;
      background-size:100% 100%;
    }
  }

</style>
