<template>
  <div :class="isProduct? 'headerTabTwo product' : 'headerTabTwo'">
    <!-- 通用（简单的一句话，一个标题）-->
    <div v-if="TitleName" class="title">{{TitleName}}</div>
    <span v-if="Detail" class="content-text">{{Detail.content[0]}}</span>

    <!--    茶饮养生-->
    <div v-if="TextContent && TextContent.length > 0" v-for="item in TextContent" :key="item.name">
      <div class="title">{{item.name}}</div>
      <img class="image" :src="report.solutions.asset_prefix + item.asset" alt="茶饮图片" />
      <span v-if="item.content.length > 0" class="tip-one">泡煮方法：</span>
      <span class="content-text">{{item.content[0]}}</span>
      <span v-if="item.content.length > 1" class="tip-one">功效：</span>
      <span class="content-text">{{item.content[1]}}</span>
    </div>

    <!-- 经典药方-->
    <div v-if="PrescriptionContent" v-for="(item , index) in PrescriptionContent" :key="index" class="prescription">
      <span class="teasty">{{item.name}}</span>
      <span class="medicinal">药物组成：</span>
      <span class="medicinal-explain">{{item.form}}</span>
      <span class="medicinal">功效：</span>
      <span class="medicinal-explain">{{item.effect}}</span>
    </div>
    <span v-if="PrescriptionContent" class="tip">
      *请在医生、药师指导下购买和服用
    </span>

    <!--预防保健操-->
    <div v-if="exercises" :style="{marginTop: '50PX'}">
      <div v-for="(item, index) in exercises.content" :key="index" class="content-text">{{item}}</div>
    </div>

    <!--穴位按压-->
    <div v-if="AcupointContent">
      <!-- 穴位按压 糖尿病 -->
      <div v-if="report.type_attention === '糖尿病' " :style="{marginTop: '50PX'}">
        <div class="content-text">{{AcupointContent[0].content[0]}}</div>
        <div class="content-text">{{AcupointContent[0].content[1]}}</div>
        <div class="content-text">{{AcupointContent[0].content[2]}}</div>
      </div>
      <!-- 穴位按压 高血压 -->
      <div v-if="report.type_attention === '高血压' ">
        <div v-for="(item, index) in AcupointContent" :key="index">
          <div class="title">{{item.name}}</div>
          <img class="image" :src="report.solutions.asset_prefix + item.asset[0]" alt="加载失败">
          <div class="tip-one">定位：</div>
          <span class="content-text">{{item.content[0]}}</span>
          <div class="tip-one">按摩方法：</div>
          <span class="content-text">{{item.content[1]}}</span>
        </div>
      </div>
    </div>

    <!--艾灸-->
    <div v-for="(item, index) in moxibustion" :key="index" :style="{marginBottom: '50px'}">
      <div class="title">{{ item.name }}</div>
      <img class="image" :src="report.solutions.asset_prefix + item.asset[0]" alt="加载失败">
      <span class="content-text">{{item.content[0]}}</span>
    </div>

    <!--足浴疗法 高血压 糖尿病-->
    <div v-for="(item, index) in footBath" :key="index">
      <div class="title" > {{ item.name }}</div>
      <img class="image" :src="report.solutions.asset_prefix + item.asset[0]" alt="加载失败">
      <div class="tip-one">{{report.type_attention === '糖尿病' ? '方药组成：' : '足浴配方：'}}</div>
      <span class="content-text">{{item.content[0]}}</span>
      <div class="tip-one">{{report.type_attention === '糖尿病' ? '适应症：' : '操作：'}}</div>
      <span class="content-text">{{item.content[1]}}</span>
      <div class="tip-one" v-if="report.type_attention === '糖尿病'">用法用量：</div>
      <span class="content-text" v-if="report.type_attention === '糖尿病'">{{item.content[2]}}</span>
    </div>


    <!--特别推荐-->
    <div v-if="isProduct">
      <ProductTzbs />
      <ProductDetail v-if="isShowProDetail" />
    </div>

  </div>
</template>

<script>
  import ProductTzbs from '../../views/physical/producttzbs.vue'
  import ProductDetail from '../../views/physical/productdetailtzbs.vue';


export default {
  name: "headertabtwo",
  props: {
    Img: String,
    SymptomsName: String,
    TextContent: Array,
    TitleName: String,
    Detail: Object,
    AcupointContent:Array,
    PrescriptionContent:Array,
    isProduct: Boolean, // 是否是产品推荐
    exercises: Object, // 预防保健操
    moxibustion: Array, // 艾灸
    footBath: Array // 足浴疗法
  },
  data(){
    return {
      report: this.$store.state.report
    }
  },
  mounted() {
  },
  components: {
    ProductTzbs,
    ProductDetail
  },
  computed: {
    isShowProDetail(){
      return this.$store.state.is_show_detail // 是否展示详情，点击boxItem 把对应的内容展示在这里
    },
    assets(){
      return this.$store.state.report.solutions && this.$store.state.report.solutions.asset_prefix;
    },
  }
}
</script>

<style lang="scss" scoped>
$bk: '../../assets/img/physical/kuang_lab_icon.png';
$commonW: 90%;
$commonL: 5%;

.headerTabTwo.product {
  background:none;
}
.headerTabTwo {
  font-size: 36px;
  color:#F1F1F1;
  width: $commonW;
  margin: 30px 0 0 $commonL;
  height: 1300px;
  overflow: auto;
  line-height:60px;
  padding-bottom: 50px;
  .title {
    font-size: 42px;
    /*height: 36px;*/
    color:#ffffff;
    padding: 80px 0 50px 5%;
  }
  .image {
    width: auto;
    height: 480px;
    margin: 0 auto;
    border-radius: 10px;
    display: block;
  }
  .tip-one {
    display: block;
    margin-left: 5%;
  }
  .content-text {
    display:block;
    margin-left: 5%;
    margin-right: 5%;
    font-size:30px;
    color:#ffffff;
    line-height:45px;
  }
  .voidesty{
    display: inline-block;
    width: 90%;
    line-height: 100px;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    margin-left: 5%;
  }
  .prescription{
    height: 33.3%;
    /*padding-top: 20px;*/
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 90px;
  }
  .teasty{
    margin-top:10px;
    display:block;
    font-size:36px;
    color:#D67206;
    font-weight: bold;
  }
  .medicinal{
    display:block;
    font-size:30px;
    height:40px;
    color:#ffffff;
    margin-top: 10px;
  }
  .medicinal-explain{
    margin-top:5px;
    display:block;
    font-size:30px;
    color:#ffffff;
    line-height:40px;
  }
  .tip{
    position: absolute;
    right: 55px;
    bottom: 0px;
    color: red;
    font-size: 30px;
  }
}
</style>
