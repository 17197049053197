<template>
  <div class="wxfemalescheme">
    <div class="title">健康方案</div>
    <div v-for="(item, index) in buttontype" :key="index">
      <div class="header">
        <img src="../../../assets/img/wx/wx_jkfabt_jcjg.png"/>
        <span>{{item}}</span>
        <img src="../../../assets/img/wx/wx_jkfabt1_jcjg.png"/>
      </div>
      <!-- 饮食调养 -->
      <div v-if="item === '饮食调养'" class="zero">
        <div class="goodfood">
          <div class="left">
            <img src='../../../assets/img/wx/wx_ys_jcjg.png'/>
            <span>宜食</span>
          </div>
          <div class="right">
            <span v-for="(e,i) in goodfood" :key="i">{{e.name}} </span>
          </div>
        </div>
        <div class="goodfood badfood">
          <div class="left">
            <img src='../../../assets/img/wx/wx_js_jcjg.png'/>
            <span style="color:#f86426">忌食</span>
          </div>
          <div class="right">
            <span v-for="(e,i) in badfood" :key="i">{{e.name}} </span>
          </div>
        </div>
        <van-tabs v-model="active" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in dietTherapy" :key="index" :title="item.name">
            <div class="item-title">配料：</div>
            <div class="food-pics">
              <div :class="(index+1)%4 !== 0 ? 'pics pics-4' : 'pics'" v-for="(items ,index) in item.asset" :key="index">
                <img :src="reportData.solutions.asset_prefix+items[1]" alt="加载失败" />
                <span>{{items[0]}}</span>
              </div>
            </div>
            <div class="item-title">清单：</div>
            <span class="item-span">{{item.content[0]}}</span>
            <div class="item-title">烹煮方法：</div>
            <span class="item-span">{{item.content[1]}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 茶饮养生 -->
      <div v-if="item === '茶饮养生'">
        <img class="commom-img" :src="reportData.solutions.asset_prefix + tea.asset[0]" alt="加载失败">
        <div class="item-title">配料：</div>
        <span class="item-span">{{tea.content && tea.content[0]}}</span>
        <div class="item-title">泡煮方法：</div>
        <span class="item-span">{{tea.content && tea.content[1]}}</span>
      </div>
      <!-- 经典药方 -->
      <div v-if="item === '经典药方'">
        <van-tabs v-model="active2" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in jdyf" :key="index" :title="item.name">
            <div class="item-title">药物组成：</div>
            <span class="item-span">{{item.content[0]}}</span>
            <div class="item-title">功效：</div>
            <span class="item-span">{{item.content[1]}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 穴位按压 -->
      <div v-if="item === '穴位按压'">
        <img class="commom-img" :src="reportData.solutions.asset_prefix + acupoint.asset" alt="加载失败">
        <div class="item-title">定位：</div>
        <span class="item-span">{{acupoint.content[0]}}</span>
        <div class="item-title">按摩方法：</div>
        <span class="item-span">{{acupoint.content[1]}}</span>
      </div>
      <!--  '中医功法' -->
      <div v-if="item === '中医功法'">
        <video-player
          v-if="poster"
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          >
        </video-player>
      </div>
      <div v-if="item === '音乐养生'" class="musics">
        <span class="item-span" style="margin-top: 30px">{{music.title}}</span>
        <img class="img" :src="`${reportData.solutions.asset_prefix}/养生音乐/脾虚/渔舟唱晚.jpg`"/>
        <audio v-if="music" controls class="audiosty" controlsList="nodownload">
        <source :src="music.url" type="audio/mpeg">
                    您的浏览器不支持 音乐播放 标签。
        </audio>
      </div>
      <!-- 起居指导 -->
      <div v-if="item === '起居指导'">
        <span class="item-span" v-for="(item,index) in hg.content" :key="index">{{item}}</span>
      </div>
      <!--  保健原则 -->
      <div v-if="item === '保健原则'">
        <div class="item-title">{{principle.content[1]}}：</div>
        <span class="item-span">{{principle.content[0]}}</span>
      </div>

    </div>
  </div>
</template>

<script>
import 'vue-video-player/src/custom-theme.css';
import 'videojs-contrib-hls';

export default {
  props: {
    reportData: Object,
  },
  data(){
    return {
      buttontype: [],
      goodfood: null,
      badfood: null,
      active: 0,
      dietTherapy: null,
      tea: null,
      active2: 0,
      jdyf: null,
      acupoint: null,

      gf:'',
      image:'',
      csvideo:'',
      sourcessrc:'',
      poster:'',
      music:'',
      playerOptions:{
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [{
          type: "video/mp4",
          src: '',
        }],
        poster: '',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      },

      hg:'',
      principle:'',
    }
  },
  created(){
    if(this.reportData.type_attention === '卵巢'){
      this.buttontype = ['饮食调养', '茶饮养生', '穴位按压', '中医功法','音乐养生', '起居指导', '保健原则']
    } else {
      if(this.reportData.health_status === '健康'){
        this.buttontype = ['饮食调养', '茶饮养生', '穴位按压', '中医功法','音乐养生', '起居指导','保健原则']
      } else {
        this.buttontype = ['饮食调养', '茶饮养生', '经典药方', '穴位按压', '中医功法','音乐养生', '起居指导','保健原则']
      }
    }
    const symptomsName = this.reportData.symptoms[0] ? this.reportData.symptoms[0].name : '健康';
    this.goodfood = this.reportData.solutions.good_food[symptomsName];
    this.badfood = this.reportData.solutions.bad_food[symptomsName];
    this.dietTherapy = this.reportData.solutions.diet_therapy && this.reportData.solutions.diet_therapy[symptomsName];
    this.tea = this.reportData.solutions.herb_tea && this.reportData.solutions.herb_tea[symptomsName];
    this.jdyf = this.reportData.solutions.prescription && this.reportData.solutions.prescription[symptomsName];
    this.acupoint = this.reportData.solutions.massage && this.reportData.solutions.massage[symptomsName];

    this.gf = this.reportData.solutions.video && this.reportData.solutions.video[symptomsName];
    this.image = this.gf && this.gf.asset && this.gf.asset[1].split('/')[1];
    this.csvideo = this.gf && this.gf.asset && this.gf.asset[0].split('/')[1];
    this.sourcessrc = this.reportData.solutions.asset_prefix + this.gf.asset[1];
    this.poster = this.reportData.solutions.asset_prefix + this.gf.asset[0];
   
    this.playerOptions.sources[0].src = this.poster;
    this.playerOptions.poster = this.sourcessrc;
    this.music = this.getMusic(this.reportData.solutions, symptomsName)

    this.hg = this.reportData.solutions.health_guidance && this.reportData.solutions.health_guidance[symptomsName];
    this.principle = this.reportData.solutions.health_principle && this.reportData.solutions.health_principle[symptomsName];
  },
  methods: {
    getMusic( solutions, symptoms ){
      const symptom = symptoms ? symptoms: '健康';
      const music = solutions && solutions.music && solutions.music[symptom];
      if (!music) return null;
      return {
        title: music.name.replace(/\..*$/, ''),
        url: solutions.asset_prefix + music.asset[0],
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.wxfemalescheme {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 30px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 62%;
    margin: 75px 0 60px 19%;
    img {
      width: 150px;
      height: 29px;
    }
    span {
      font-family: PingFangSC-Regular;
	    font-size: 45px;
      color: #30c3fa;
      width: 260px;
      text-align: center;
    }
  }
  .common-one {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .commom-img {
    display: block;
    border-radius: 30px;
	  border: solid 1px #dcdcdc;
    margin: 20px 0;
  }
  .common-text {
    font-family: SourceHanSansCN-Regular;
    font-size: 39px;
    color: #666666;
    line-height: 62px;
  }
  .zero {
    .goodfood{
      @extend .common-one;
      border-radius: 30px;
      border: solid 1px #dcdcdc;
      padding: 33px 44px 33px 0;
      .left {
        @extend .common-one;
        flex-direction: column;
        justify-content: center;
        width: 20%;
        img {
          width: 45px;
          height: 45px;
        }
        span {
          font-size: 42px;
          color: #35c8e3;
          font-family: SourceHanSansCN-Regular;
          margin-top: 10px;
        }
      }
      .right {
        width: 80%;
        font-family: PingFangSC-Regular;
        font-size: 36px;
        color: #666666;
        line-height: 52px;
      }
    }
    .badfood {
      margin: 30px 0 20px 0;
    }
    .food-pics {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .pics {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-family: SourceHanSansCN-Regular;
      line-height: 72px;
      color: #666666;
      img {
        width: 210px;
      }
    }
    .pics-4 {
      margin-right: 17px;
    }
  }
  .item-title {
    font-family: PingFangSC-Regular;
	  font-size: 42px;
    color: #333333;
    margin-top: 42px;
    margin-bottom: 20px;
  }
  .item-span{
    font-family: SourceHanSansCN-Regular;
	  font-size: 39px;
    color: #666666;
    line-height: 52px;
  }
  .musics {
    border: solid 1.5px #dcdcdc;
    padding: 0px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      width: 100%;
      margin-top: 10px;
    }
    .audiosty{
      width: 100%;
      margin-top: 10px;
      zoom: 2;
    }
  }
}
</style>

<style>
  .wxfemalescheme .van-tab {
    font-size: 42px;
    color: #333;
    line-height: 88px;
  }

  .wxfemalescheme .van-tabs--line .van-tabs__wrap {
    height: 88px;
  }

  .wxfemalescheme .van-tabs__line {
    width: 140px;
  }
</style>