<template>
  <div class="product-detail" ref="toptzbs">
    <div class="header">
      <van-icon name="arrow-left" size="40px" color="#333" @click="goback"/>
      <span class="one" @click="goback">返回</span>
      <span class="two">特别推荐</span>
    </div>
    <!--  -->
    <div class="content">
      <img :src="itemDate.image"/>
      <div class="both">
        <div :class="itemDate.qr_code && itemDate.market_url ? 'left' : 'left left-two'">
          <div class="product">
            <span class="text1">{{itemDate.name}}</span>
            <span class="text2">{{itemDate.price? `¥${itemDate.price}` : ''}}</span>
          </div>
          <span class="text3">{{itemDate.desc}}</span>
        </div>
        <div v-if="itemDate.market_url" class="right">
<!--          <img v-if="itemDate.qr_code" :src="itemDate.qr_code" alt="产品二维码" />-->
<!--          <a v-if="itemDate.market_url" class="buy" :href="itemDate.market_url" target="_blank">点击购买</a>-->
          <qrcode-vue  v-if="itemDate.market_url" :value='itemDate.market_url' size='120'></qrcode-vue>

        </div>
      </div>
      <div v-for="(item, id) in itemDate.photos" :key="id" class="img">
        <img :src="item.image" alt="产品介绍" />
      </div>
    </div>
    <!--  -->
    <div class="footer">
      注：特别推荐所展示的商品/服务的标题、价格、详情等信息内容系由合作方发布，其真实性、准确性和合法性由信息拥有者（合作方）负责。本站不提供任何保证，并不承担任何法律责任。
    </div>
    <button v-if="marker !=='10.1'"  @click="topFunction" class="toTop" title="回顶部">返回顶部</button>
  </div>
</template>

<script>
  import QrcodeVue from 'qrcode.vue'
  import { queryObject } from '../../../utils/common.js';
  const { marker } = queryObject();

export default {
  data(){
    return {
      itemDate: null,
      marker
    }
  },
  beforeMount(){
    this.itemDate = this.$store.state.current_product; // 当前展示的产品详情
  },
  methods: {
    goback(){
      this.$store.commit('SET_DETAIL_SHOW',false); // 展示详情页
    },
    topFunction(){
      this.$refs.toptzbs.scrollTop = 0;
    }
  },
  components: {
    QrcodeVue
  },
}
</script>

<style lang="scss" scoped>
.product-detail {
  position:fixed;
  top:0;
  left:0;
  background-color: #ffffff;
  height:100%;
  width:100%;
  overflow:auto;
  .header {
    padding:100px 30px 50px 30px;
    display: flex;
    align-items: center;
    .one {
      font-size: 36px;
      color: rgb(102, 102, 102);
      font-weight: bold;
    }
    .two {
      margin-left: 30%;
      font-size: 60px;
      color: rgb(51, 51, 51);
      font-family: TRENDS;
      text-align: center;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  .content {
    .both {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 92%;
      margin-left: 4%;
      margin-top: 10px;
      .left {
        width: 90%;
        .product {
          margin: 20px 40px 10px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .text1 {
            font-size: 36px;
            color: #333333;
            font-weight: bold;
          }
          .text2 {
            font-size: 36px;
            color: #00adfe;
            font-weight: bold;
          }
        }
        .text3 {
          margin: 0 40px 10px 0;
          color: #666666;
          font-size: 32px;
        }
      }
      .left-two {
        width: 100%;
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
          width: 120px;
          height: 120px;
        }
        .buy {
          width: 120px;
          color: #333;
          font-size: 24px;
          border: 1px solid #333;
          border-radius: 8px;
          padding: 6px 0;
          text-align: center;
          margin-top: 6px;
        }
     }
    }
    .img {
      /*margin: 30px 0;*/
      img {
        display: block;
      }
    }
  }

  .footer {
    background-color: #f5f5f5;
    font-size: 36px;
    color: #999999;
    line-height: 60px;
    padding: 30px 60px;
  }
  .toTop{
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #b8b6b6;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
  }
}
</style>
