var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wxfemalescheme" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("健康方案")]),
      _vm._l(_vm.buttontype, function(item, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "header" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/img/wx/wx_jkfabt_jcjg.png")
              }
            }),
            _c("span", [_vm._v(_vm._s(item))]),
            _c("img", {
              attrs: {
                src: require("../../../assets/img/wx/wx_jkfabt1_jcjg.png")
              }
            })
          ]),
          item === "饮食调养"
            ? _c(
                "div",
                { staticClass: "zero" },
                [
                  _c("div", { staticClass: "goodfood" }, [
                    _vm._m(0, true),
                    _c(
                      "div",
                      { staticClass: "right" },
                      _vm._l(_vm.goodfood, function(e, i) {
                        return _c("span", { key: i }, [
                          _vm._v(_vm._s(e.name) + " ")
                        ])
                      }),
                      0
                    )
                  ]),
                  _c("div", { staticClass: "goodfood badfood" }, [
                    _vm._m(1, true),
                    _c(
                      "div",
                      { staticClass: "right" },
                      _vm._l(_vm.badfood, function(e, i) {
                        return _c("span", { key: i }, [
                          _vm._v(_vm._s(e.name) + " ")
                        ])
                      }),
                      0
                    )
                  ]),
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.active,
                        callback: function($$v) {
                          _vm.active = $$v
                        },
                        expression: "active"
                      }
                    },
                    _vm._l(_vm.dietTherapy, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("配料：")
                          ]),
                          _c(
                            "div",
                            { staticClass: "food-pics" },
                            _vm._l(item.asset, function(items, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  class:
                                    (index + 1) % 4 !== 0
                                      ? "pics pics-4"
                                      : "pics"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.reportData.solutions.asset_prefix +
                                        items[1],
                                      alt: "加载失败"
                                    }
                                  }),
                                  _c("span", [_vm._v(_vm._s(items[0]))])
                                ]
                              )
                            }),
                            0
                          ),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("清单：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[0]))
                          ]),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("烹煮方法：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[1]))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "茶饮养生"
            ? _c("div", [
                _c("img", {
                  staticClass: "commom-img",
                  attrs: {
                    src:
                      _vm.reportData.solutions.asset_prefix + _vm.tea.asset[0],
                    alt: "加载失败"
                  }
                }),
                _c("div", { staticClass: "item-title" }, [_vm._v("配料：")]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.tea.content && _vm.tea.content[0]))
                ]),
                _c("div", { staticClass: "item-title" }, [
                  _vm._v("泡煮方法：")
                ]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.tea.content && _vm.tea.content[1]))
                ])
              ])
            : _vm._e(),
          item === "经典药方"
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.active2,
                        callback: function($$v) {
                          _vm.active2 = $$v
                        },
                        expression: "active2"
                      }
                    },
                    _vm._l(_vm.jdyf, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("药物组成：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[0]))
                          ]),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("功效：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[1]))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "穴位按压"
            ? _c("div", [
                _c("img", {
                  staticClass: "commom-img",
                  attrs: {
                    src:
                      _vm.reportData.solutions.asset_prefix +
                      _vm.acupoint.asset,
                    alt: "加载失败"
                  }
                }),
                _c("div", { staticClass: "item-title" }, [_vm._v("定位：")]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.acupoint.content[0]))
                ]),
                _c("div", { staticClass: "item-title" }, [
                  _vm._v("按摩方法：")
                ]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.acupoint.content[1]))
                ])
              ])
            : _vm._e(),
          item === "中医功法"
            ? _c(
                "div",
                [
                  _vm.poster
                    ? _c("video-player", {
                        ref: "videoPlayer",
                        refInFor: true,
                        staticClass: "video-player vjs-custom-skin",
                        attrs: { playsinline: true, options: _vm.playerOptions }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          item === "音乐养生"
            ? _c("div", { staticClass: "musics" }, [
                _c(
                  "span",
                  {
                    staticClass: "item-span",
                    staticStyle: { "margin-top": "30px" }
                  },
                  [_vm._v(_vm._s(_vm.music.title))]
                ),
                _c("img", {
                  staticClass: "img",
                  attrs: {
                    src:
                      _vm.reportData.solutions.asset_prefix +
                      "/养生音乐/脾虚/渔舟唱晚.jpg"
                  }
                }),
                _vm.music
                  ? _c(
                      "audio",
                      {
                        staticClass: "audiosty",
                        attrs: { controls: "", controlsList: "nodownload" }
                      },
                      [
                        _c("source", {
                          attrs: { src: _vm.music.url, type: "audio/mpeg" }
                        }),
                        _vm._v(" 您的浏览器不支持 音乐播放 标签。 ")
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          item === "起居指导"
            ? _c(
                "div",
                _vm._l(_vm.hg.content, function(item, index) {
                  return _c("span", { key: index, staticClass: "item-span" }, [
                    _vm._v(_vm._s(item))
                  ])
                }),
                0
              )
            : _vm._e(),
          item === "保健原则"
            ? _c("div", [
                _c("div", { staticClass: "item-title" }, [
                  _vm._v(_vm._s(_vm.principle.content[1]) + "：")
                ]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.principle.content[0]))
                ])
              ])
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        attrs: { src: require("../../../assets/img/wx/wx_ys_jcjg.png") }
      }),
      _c("span", [_vm._v("宜食")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        attrs: { src: require("../../../assets/img/wx/wx_js_jcjg.png") }
      }),
      _c("span", { staticStyle: { color: "#f86426" } }, [_vm._v("忌食")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }