<template>
  <div class="headerTabOne">
    <div class="top">
      <div class="left" :style="styleDiff">
        <div class="left-top">
          <img src='../../assets/img/report/ys_jkfa_icon.png'/>
          <span>宜食</span>
        </div>
        <span class="text">{{goodfood.content[0]}}</span>
      </div>
      <div class="left right" v-if="badfood.content.length > 0">
        <div class="left-top">
          <img src='../../assets/img/common/js_jkfa_icon.png'/>
          <span>忌食</span>
        </div>
        <span class="text">{{badfood.content[0] || '暂无'}}</span>
      </div>
    </div>
    <!--饮食调养（慢病是[]）  -->
    <div class="Divys" v-if="solutions">
      <!--慢病-->
      <div :style="{marginBottom: '50px'}">
        <div v-for="item in ysty" :key="item.name" >
          <div class="title">{{ item.name }}</div>
          <img class="image" :src="`${solutions.asset_prefix}${item.asset[0]}`"/>
<!--          <span v-if="reportData.type_attention === '糖尿病' " class="title2">配料：</span>-->
<!--          <span v-if="reportData.type_attention === '糖尿病' " class="title3">{{item.content[0]}}</span>-->
          <span class="title2">烹煮方法：</span>
          <span class="title3" v-for="(step,index) in item.content" :key="index">{{ step }}</span>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return {
      symptoms: null,
      solutions: null,
      goodfood: null,
      badfood: null,
      ysty: null,
      reportData: this.$store.state.report
    }
  },
  props: {
    isChronic: {
      type: Boolean,
      default: false
    }
  },
  beforeMount(){
    const goodFieldName = this.isChronic? 'chronic_disease_good_food' : 'tizhi_good_food';
    const badFieldName = this.isChronic? 'chronic_disease_bad_food' : 'tizhi_bad_food';
    const dietFieldName = this.isChronic? 'chronic_disease_diet_therapy' : 'tizhi_diet_therapy';

    this.symptoms = this.$store.state.report.symptoms
    this.solutions = this.$store.state.report.solutions
    const symptomsName = this.symptoms[0].name;
    this.goodfood = this.solutions[goodFieldName][symptomsName];
    this.badfood = this.solutions[badFieldName][symptomsName];
    this.ysty = this.solutions[dietFieldName][symptomsName];
    // const aaa = ysty.length * 850;

  },
  computed: {
    styleDiff(){
      // 宜食、忌食
      if(this.badfood.content.length > 0) {
        return {
          maxWidth: '50%'
        }
      } else {
        return {
          maxWidth: '100%',
          borderRight: 'none'
        }

      }
    }
  }
}
</script>

<style lang="scss" scoped>
$tjysbj: '../../assets/img/physical/kuang_lab_icon.png';
$titlebj: '../../assets/img/report/btbj_ysfa_icon.png';

$commonW: 90%;
$commonL: 5%;
.headerTabOne {
  max-height: 1400px;
  overflow: auto;

  .top{
    margin: 20px 0;
    width: $commonW;
    margin-left: $commonL;
    display: flex;
    flex-direction: row;
    .left {

      height: 250px;
      color:white;
      // background-color: #333;
      border-right: 3px solid #2f7284;
      margin-right: 15px;
      .left-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        img {
          width: 35px;
          height: 35px;
        }
        span {
          font-size:35px;
          line-height: 35px;
          color:#ffffff;
          margin-left: 10px;
        }
      }
      .text {
        font-size:25px;
        line-height: 35px;
        color:#ffffff;
        padding: 0 15px 10px 0;
      }
    }
    .right {
      width: 47%;
      border-right: 0px solid #2f7284;
    }
  }
  // .title {
  //   width: $commonW;
  //   margin-left: $commonL;
  //   color: #fff;
  //   font-size: 36px;
  //   height:40px;
  // }
  .Divys{
    width: $commonW;
    margin-left: $commonL;
    background:url($tjysbj);
    background-size:100% 100%;
    margin-top: 60px;
    padding-bottom: 120px;
    .title {
      background:url($titlebj);
      background-size:100% 100%;
      width: 500px;
      height: 137px;
      margin-left: 24%;
      font-size:40px;
      color:#ffffff;
      line-height:100px;
      text-align:center;
    }
    .image {
      width: auto;
      height: 480px;
      margin: 0 auto;
      border-radius: 10px;
      display: block;
    }
    .title2 {
      display:block;
      margin-left: 5%;
      font-size:36px;
      line-height:80px;
      color:#ffffff;
    }
    .title3 {
      margin: 10px 0 20px 5%;
      width: 90%;
      display:block;
      font-size:30px;
      color:#ffffff;
      line-height:45px;
    }
  }
}
</style>