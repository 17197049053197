var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isFinish
    ? _c("div", { staticClass: "pulse_report" }, [
        _c("div", { staticClass: "title" }, [_vm._v("中医脉诊报告")]),
        _c("div", { staticClass: "personal" }, [
          _c("p", [_vm._v("报告编号：" + _vm._s(_vm.pulse.id))]),
          _c("p", [_vm._v("检测时间：" + _vm._s(_vm.pulse.create_time))])
        ]),
        _c("div", { staticClass: "basicInfo" }, [
          _c("div", { staticClass: "partTitle" }, [_vm._v("一、基本情况")]),
          _c("table", { staticClass: "basicTable" }, [
            _c("tr", [
              _c("td", [_vm._v("姓名")]),
              _c("td", [_vm._v(_vm._s(_vm.pulse.name))]),
              _c("td", [_vm._v("性别")]),
              _c("td", [_vm._v(_vm._s(_vm.pulse.gender))]),
              _c("td", [_vm._v("手机号")]),
              _c("td", [_vm._v(_vm._s(_vm.pulse.cellphone))])
            ]),
            _c("tr", [
              _c("td", [_vm._v("身份证号")]),
              _c("td", { attrs: { colspan: "5" } }, [
                _vm._v(_vm._s(_vm.pulse.idcard))
              ])
            ]),
            _c("tr", [
              _c("td", [_vm._v("症状")]),
              _c("td", { attrs: { colspan: "5" } }, [
                _vm._v(_vm._s(_vm.pulse.medical_history))
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "detailInfo" }, [
          _c("div", { staticClass: "partTitle" }, [_vm._v("二、脉诊分析")]),
          _c("p", { staticClass: "detailTitle" }, [_vm._v("分脉脉象")]),
          _c("div", { staticClass: "handInfo" }, [
            _c("div", { staticClass: "leftHand" }, [
              _c("div", { staticClass: "handTitle" }, [_vm._v("左手脉象")]),
              _c("img", {
                attrs: { src: _vm.leftResult.image, alt: "左手脉象" }
              }),
              _c("div", { staticClass: "handDetail" }, [
                _c("div", { staticClass: "handPart" }, [
                  _c("p", { staticClass: "partName" }, [_vm._v("寸")]),
                  _c("p", [_vm._v(_vm._s(_vm.leftResult.cun.join("、")))])
                ]),
                _c("div", { staticClass: "handPart" }, [
                  _c("p", { staticClass: "partName" }, [_vm._v("关")]),
                  _c("p", [_vm._v(_vm._s(_vm.leftResult.guan.join("、")))])
                ]),
                _c("div", { staticClass: "handPart" }, [
                  _c("p", { staticClass: "partName" }, [_vm._v("尺")]),
                  _c("p", [_vm._v(_vm._s(_vm.leftResult.chi.join("、")))])
                ])
              ])
            ]),
            _c("div", { staticClass: "rightHand" }, [
              _c("div", { staticClass: "handTitle" }, [_vm._v("右手脉象")]),
              _c("img", {
                attrs: { src: _vm.rightResult.image, alt: "右手脉象" }
              }),
              _c("div", { staticClass: "handDetail" }, [
                _c("div", { staticClass: "handPart" }, [
                  _c("p", { staticClass: "partName" }, [_vm._v("寸")]),
                  _c("p", [_vm._v(_vm._s(_vm.rightResult.cun.join("、")))])
                ]),
                _c("div", { staticClass: "handPart" }, [
                  _c("p", { staticClass: "partName" }, [_vm._v("关")]),
                  _c("p", [_vm._v(_vm._s(_vm.rightResult.guan.join("、")))])
                ]),
                _c("div", { staticClass: "handPart" }, [
                  _c("p", { staticClass: "partName" }, [_vm._v("尺")]),
                  _c("p", [_vm._v(_vm._s(_vm.rightResult.chi.join("、")))])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "totalHand" }, [
            _c("p", { staticClass: "detailTitle" }, [_vm._v("总脉脉象")]),
            _c("p", { staticClass: "totalResult" }, [
              _vm._v(_vm._s(_vm.totalPulse.final_pulse))
            ]),
            _c("p", { staticClass: "totalTips" }, [
              _vm._v("提示：" + _vm._s(_vm.totalPulse.desc))
            ])
          ]),
          _vm.hasBlood
            ? _c("div", { staticClass: "bloodResult" }, [
                _c("p", { staticClass: "detailTitle" }, [_vm._v("血压")]),
                _c("table", { staticClass: "basicTable" }, [
                  _c("tr", [
                    _c("td", [_vm._v("舒张压")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.bloodResult.blood_dia || "-") + "mmHg")
                    ]),
                    _c("td", [_vm._v("收缩压")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.bloodResult.blood_sys || "-") + "mmHg")
                    ]),
                    _c("td", [_vm._v("心率")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.bloodResult.heart_rate || "-") + "次/分"
                      )
                    ])
                  ])
                ]),
                _c("p", { staticClass: "totalTips" }, [
                  _vm._v("提示：" + _vm._s(_vm.totalPulse.desc || "-"))
                ])
              ])
            : _vm._e()
        ]),
        _c("footer", [_vm._v("由上海祉云医疗科技有限公司提供技术支持")])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }