import axios from './index.js';
import { queryObject } from '../../utils/common.js'

const { category } = queryObject();

function getBaseUrl(){
  let reportUrl = '';
  // 区分健康状态、体质辨识
  if (category === '2') {
    reportUrl = '/api30'; // 体质辨识(绿色版本)
  } else {
    reportUrl = '/api16'; // 健康状态(蓝色版本)
  }
  return reportUrl;
}


// 报告数据
export function getReport(params){
  // 测试id可以用 SRv0n20WNYQEhx2Q
  const url =  `${getBaseUrl()}/share/${params.id}`;
  return axios.get(url)
}

// 更年期/卵巢
export function getMenopauseOvary(params){
  // 测试id可以用 SO9YLNfOMxQnd37Q
  const url =  `/api30/menopause_ovary_share/${params.id}`;
  return axios.get(url)
}

// 慢病 糖尿病/高血压  高血压id：SCF0glDx4z5CHnHw  
// 糖尿病健康id：SCf8gkcayXs9ApiQ   非健康id：SC1fHPERJIYXGCUw
export function getChronicReport(params){
  const url =  `/api30/chronic_disease/share/${params.id}`;
  return axios.get(url)
}

// 面诊数据
export function getfaceReport(params){
  const url =  `/api16/face/${params.id}`;
  return axios.get(url)
}

// 舌诊数据
export function gettongueReport(params){
  const url =  `/api16/tongue/${params.id}`;
  return axios.get(url)
}

// 知识库依据
export function getKnowledge(params){
  const url =  '/api16/notice/knowledge_basis';
  return axios.get(url)
}

// 健康状态辨识总提纲解释说明
export function getHealthExplain(params){
  const url =  '/api16/notice/health_status_explain';
  return axios.get(url)
}

// 获取脉诊报告
export function getPulseReport({reportId}) {
  const url = `/api33/submitpulse/${reportId}`;
  return axios.get(url)
}

// 产品详情-用户点击次数埋点
export function recordClick(data) {
  const url = '/api33/count';
  return axios.post(url, data)
}





