// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/wx/wx_bj_jcjg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/wx/wx_jkzs_jcjg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".wxhealthyheader[data-v-1a8f9f33] {\n  width: 100%;\n  min-height: 540px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  overflow: hidden;\n  display: flex;\n  flex-direction: row;\n  padding-bottom: 54px;\n}\n.wxhealthyheader .left[data-v-1a8f9f33] {\n    color: #fff;\n    margin: -10px 0 0 45px;\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n}\n.wxhealthyheader .left h2[data-v-1a8f9f33] {\n      font-size: 52px;\n      font-family: PingFangSC-Medium;\n      margin-top: 180px;\n}\n.wxhealthyheader .left span[data-v-1a8f9f33] {\n      display: block;\n      font-size: 36px;\n      font-family: SourceHanSansCN-Regular;\n      line-height: 54px;\n}\n.wxhealthyheader .left .bottom[data-v-1a8f9f33] {\n      position: absolute;\n      bottom: 40px;\n}\n.wxhealthyheader .right[data-v-1a8f9f33] {\n    margin-left: -10%;\n    background-color: yellow;\n    width: 60%;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    background-size: cover;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n.wxhealthyheader .right div[data-v-1a8f9f33] {\n      font-family: PingFangSC-Medium;\n      font-size: 38px;\n      color: #ffffff;\n      margin: 40px 0 0 60px;\n}\n.wxhealthyheader .right span[data-v-1a8f9f33] {\n      font-family: PingFangSC-Medium;\n      font-size: 60px;\n      color: #ffffff;\n      margin: 40px 0 0 60px;\n}\n", ""]);
// Exports
module.exports = exports;
