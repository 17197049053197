<template>
  <div class="wxpulseresult">
    <div class="title">脉诊结果</div>
    <img :src="pulse[0].pulse_image" alt="脉诊图片" />
    <div class="total">
      <span class="title2">总脉</span>
      <div class="tip" v-for="(item, index) in summaryArr" :key="index">{{item}}</div>
    </div>
    <span class="title2">心率</span><span class="rate">{{pulse[0].heart_rate}}次/分</span>
    <div class="tip2">提示：{{pulse[0].summary_attention}}</div>
  </div>
</template>

<script>
export default {
  props:{
    pulse: Array
  },
  data(){
    return {
      summaryArr: []
    }
  },
  beforeMount(){
   this.summaryArr = this.pulse[0].summary.split(',')
  }
}
</script>

<style lang="scss" scoped>
.wxpulseresult {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 30px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
  img {
    width: 96%;
    margin-left: 2%;
  }
  .title2 {
    font-family: SourceHanSansCN-Medium;
	  font-size: 45px;
    color: #333333;
    margin-right: 120px;
  }
  .total {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0 50px 0;
    .tip {
      width: 102px;
      height: 102px;
      border: solid 1px #333333;
      border-radius: 51px;
      color: #333333;
      font-size: 45px;
      font-family: SourceHanSansCN-Regular;
      text-align: center;
      line-height: 102px;
      margin-right: 60px;
    }
  }
  .rate {
    font-family: SourceHanSansCN-Regular;
    font-size: 45px;
    line-height: 54px;
    color: #333333;
  }
  .tip2 {
    font-family: SourceHanSansCN-Regular;
    font-size: 42px;
    line-height: 63px;
    color: #666666;
    margin-top: 60px;
  }
}
</style>