<template>
  <div class="wxdialecticalchart">
    <div class="title">中医辩证分型</div>
    <div id="dialectical" :style="{width: '100%', height: '590px'}"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: "wxdialecticalchart",
  props: {
    symptoms: Array,
    symptomStatus: Array,
    beforeSymptomStatus: Array,
  },
  data(){
    return {
      indicatorArray: [],
      scoreArray: [],
      beforeIndicatorArray: [],
      beforeScoreArray:[],
      maxScoreDic: {肾虚肝郁型: 267, 心肾不交型: 135, 阴虚火旺型: 236, 肾阴虚型: 218, 肾阳虚型: 90, 肾阴阳两虚型: 138,
      肾虚肝郁: 318, 肾虚血瘀: 242, 肝肾阴虚: 338, 痰湿凝滞: 191, 脾肾两虚: 318, 气滞血瘀: 223, 气血两亏: 227,}

    }
  },
  beforeMount(){
    var that = this
    // 本次
    this.symptomStatus.forEach(function(element){
      const nameString = that.symptoms.length > 0 ? that.symptoms[0].name : ''
      if(element.name === nameString) {
        that.indicatorArray.push({
          name: element.name, max: that.maxScoreDic[element.name], color: '#ffa525'
        })
      } else {
        that.indicatorArray.push({
          name: element.name, max: that.maxScoreDic[element.name]
        })
      }

      if(element.score >=0 ) {
        that.scoreArray.push(element.score)
      }else {
        that.scoreArray.push(0)
      }
    })
    //上次
    if(this.beforeSymptomStatus) {
      this.beforeSymptomStatus.forEach(function(element){
        that.beforeIndicatorArray.push({
          name: element.name, max: that.maxScoreDic[element.name]
        })
        if(element.score >=0 ) {
          that.beforeScoreArray.push(element.score)
        }else {
          that.beforeScoreArray.push(0)
        }
      })
    }else {
      this.symptomStatus.forEach(function(element){
        that.beforeScoreArray.push(0)
      })
    }
  },
  mounted(){
    this.drawChart()
    // console.log(this.symptomStatus, this.beforeSymptomStatus)
  },
  methods: {
    drawChart(){
      const myChart = echarts.init(document.getElementById('dialectical'));
      myChart.setOption({
        legend: {
          data: this.beforeSymptomStatus === null ? ['本次'] : ['本次', '上次'],
          show: true,
          right: "1%",
          width: "auto",
          orient: "vertical",
          itemWidth: 50,
          itemHeight: 6,
          selectedMode: false,
          textStyle: {
            color: "#333",
            fontSize: 30,
          }
        },
        color:this.beforeSymptomStatus === null ? ['#ffad2c'] : ['#ffad2c','#069eff'],
        radar: {
          center: ['50%', '50%'],
          startAngle: 90,
          radius: 235,
          splitNumber: 5,
          indicator: this.indicatorArray,
          axisName: {
            color:'#999',
            formatter(value) {
              return `{a|${value}}`;
            },
            rich: {
              a: {
                fontSize: 36,
                lineHeight: 40,
              }
            },
          },
          axisLine: { //坐标轴
            show: false
          },
          splitLine: {
            lineStyle: {
              width: 1,
              color: '#fff'
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(7, 158, 255, 0.5)','rgba(7, 158, 255, 0.4)','rgba(7, 158, 255, 0.3)','rgba(7, 158, 255, 0.2)','rgba(7, 158, 255, 0.1)'],
            }
          },
        },
        series: [{
          type: 'radar',
          symbol: "none", // 去掉图表中各个图区域的边框线拐点
          data: [
            {
              name: '本次',
              value: this.scoreArray,
              lineStyle: {
                color: '#ffad2c',
                width: 3,
                type:'solid'
              },
            },
            {
              name: '上次',
              value: this.beforeScoreArray,
              lineStyle: {
                color: '#069eff',
                width: this.beforeSymptomStatus === null ? 0 : 3,
                type:'solid' //dotted
              },
            },
          ]
        }]
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.wxdialecticalchart {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 60px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 340px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
}
</style>
