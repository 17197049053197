<template>
  <div class="pulse_report" v-if="isFinish">
    <div class="title">中医脉诊报告</div>
    <div class="personal">
      <p>报告编号：{{pulse.id}}</p>
      <p>检测时间：{{pulse.create_time}}</p>
    </div>
    <div class="basicInfo">
      <div class="partTitle">一、基本情况</div>
      <table class="basicTable">
        <tr>
          <td>姓名</td>
          <td>{{pulse.name}}</td>
          <td>性别</td>
          <td>{{pulse.gender}}</td>
          <td>手机号</td>
          <td>{{pulse.cellphone}}</td>
        </tr>
        <tr>
          <td>身份证号</td>
          <td colspan="5">{{pulse.idcard}}</td>
        </tr>
        <tr>
          <td>症状</td>
          <td colspan="5">{{pulse.medical_history}}</td>
        </tr>
      </table>
    </div>
    <div class="detailInfo">
      <div class="partTitle">二、脉诊分析</div>
      <p class="detailTitle">分脉脉象</p>
      <div class="handInfo">
        <div class="leftHand">
          <div class="handTitle">左手脉象</div>
          <img :src="leftResult.image" alt="左手脉象">
          <div class="handDetail">
            <div class="handPart">
              <p class="partName">寸</p>
              <p>{{ leftResult.cun.join('、') }}</p>
            </div>
            <div class="handPart">
              <p class="partName">关</p>
              <p>{{ leftResult.guan.join('、') }}</p>
            </div>
            <div class="handPart">
              <p class="partName">尺</p>
              <p>{{ leftResult.chi.join('、') }}</p>
            </div>
          </div>
        </div>
        <div class="rightHand">
          <div class="handTitle">右手脉象</div>
          <img :src="rightResult.image" alt="右手脉象">
          <div class="handDetail">
            <div class="handPart">
              <p class="partName">寸</p>
              <p>{{ rightResult.cun.join('、') }}</p>
            </div>
            <div class="handPart">
              <p class="partName">关</p>
              <p>{{ rightResult.guan.join('、') }}</p>
            </div>
            <div class="handPart">
              <p class="partName">尺</p>
              <p>{{ rightResult.chi.join('、') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="totalHand">
        <p class="detailTitle">总脉脉象</p>
        <p class="totalResult">{{totalPulse.final_pulse}}</p>
        <p class="totalTips">提示：{{totalPulse.desc}}</p>
      </div>
      <div class="bloodResult" v-if="hasBlood">
        <p class="detailTitle">血压</p>
        <table class="basicTable">
          <tr>
            <td>舒张压</td>
            <td>{{bloodResult.blood_dia || '-'}}mmHg</td>
            <td>收缩压</td>
            <td>{{bloodResult.blood_sys || '-'}}mmHg</td>
            <td>心率</td>
            <td>{{bloodResult.heart_rate || '-'}}次/分</td>
          </tr>
        </table>
<!--        <p class="totalTips">提示：{{ bloodResult.blood_attention || '-'}}</p>-->
        <p class="totalTips">提示：{{totalPulse.desc || '-'}}</p>
      </div>
    </div>
    <footer>由上海祉云医疗科技有限公司提供技术支持</footer>
  </div>
</template>

<script>
  import { getPulseReport } from '@/api/report';
  export default {
    name: "pulse",
    // created(){
    //   this.getData();
    // },
    mounted(){
      this.getData();
    },
    data(){
      return {
        pulse: {}, // 脉诊数据
        leftHand: '左手脉象',
        leftResult: {},
        rightHand: '右手脉象',
        rightResult: {},
        bloodData: {},  // 血压的数据
        bloodResult: {},
        isFinish: false, // 异步加载数据是否已经完成
        isNoRecord: false, // 默认没有脉诊数据
        totalPulse: {}, // 总脉
        hasBlood: false, // 是否有血压版块
      }
    },
    methods: {
      getData(){
        const reportId = this.$route.params.id;
        getPulseReport({reportId}).then( (res)=> {
          if(res && res.status_code === 10000) {
            const data = res.data;
            this.pulse = data;
            this.leftResult = {
              image: data.l_pulse_img, // 图片地址
              cun: data.l_cun, // 寸
              guan: data.l_guan, // 关
              chi: data.l_chi, // 尺
              tips: this.pulse.l_pulse_desc // 提示
            };
            this.rightResult = {
              image: data.r_pulse_img, // 图片地址
              cun: data.r_cun, // 寸
              guan: data.r_guan, // 关
              chi: data.r_chi, // 尺
              tips: data.r_pulse_desc // 提示
            };

            this.totalPulse = { // 总脉
              final_pulse: data.final_pulse && data.final_pulse.replace(/,/g,'、'), // 总脉结论
              desc: data.final_pulse_desc // 总脉提示
            };

            if(!data.blood_dia || !data.blood_sys){ // 可能存在没有血压的结果
              this.hasBlood = false;
            } else {
              this.bloodResult = {
                blood_dia: data.blood_dia, // 舒张压
                blood_sys: data.blood_sys, // 收缩压
                heart_rate: data.heart_rate, // 心率
                blood_attention:data.blood_attention // 血压提示
              };
              this.hasBlood = true;
            }
          } else {
            throw Error('请求报告页面数据失败了');
          }
          this.isFinish = true;
        }).catch( () => {
          this.isNoRecord = true;
          this.isFinish = true;
        })
      },
    }
  }
</script>

<style scoped>

  .pulse_report {
    width: 90%;
    margin: 0 auto;
    padding-top: 50px;
    color: #333333;
  }

  .title {
    text-align: center;
    font-size: 26px;
  }

  .personal {
    float: right;
    font-size: 12px;
    overflow: auto;
  }

  .personal p{
    margin-bottom: 5px;
  }

  .basicInfo {
    margin-top: 30px;
  }

  .partTitle {
    color: #333333;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .basicTable {
    font-size: 16px;
    border-collapse: collapse;
    width: 100%;
  }

  td {
    padding: 8px 15px;
    border: 1px solid #cccccc;
  }

  td:nth-child(odd){
    /*color: #999999;*/
    width: 18%;
  }

  .handInfo {
    display: flex;
    justify-content: space-between;
  }

  .leftHand,
  .rightHand {
    width: 46%;
    border: 1px solid #cccccc;
  }

  .handInfo img {
    width: 80%;
    height: auto;
    margin:0 auto;
    display: block;
  }

  .detailTitle {
    text-align: center;
    font-weight: bold;
    margin: 40px 0 20px 0;
  }

  .handTitle {
    text-align: center;
    background-color: #CEE8F4;
    /*color: #333333;*/
    font-size: 15px;
    padding: 5px 0;
  }

  .handDetail{
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  .handPart {
    text-align: center;
  }

  .partName {
    width: 26px;
    height: 26px;
    color: #1B9EDB;
    border: 1px solid #1B9EDB;
    border-radius: 50%;
    text-align: center;
    line-height: 26px;
    margin: 10px auto;
  }

  .totalHand {
    margin: 30px 0 10px 0;

  }

  .totalResult {
    font-weight: bold;
    margin: 10px 0;
  }

  .totalTips {
    /*color: #666666;*/
    line-height: 24px;
    margin-top: 10px;
  }

  footer {
    margin-top: 80px;
    text-align: center;
    font-size: 12px;
  }

</style>