var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wxproduct" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("特别推荐")]),
      _c(
        "van-tabs",
        {
          attrs: {
            swipeable: "",
            "title-active-color": "#30c3fa",
            color: "#30c3fa",
            "title-inactive-color": "#333333"
          },
          on: {
            click: function($event) {
              return _vm.scrollTop()
            }
          },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        _vm._l(_vm.productData, function(item, index) {
          return _c("van-tab", { key: index, attrs: { title: item.name } }, [
            _c(
              "div",
              {
                ref: "productBox" + index,
                refInFor: true,
                staticClass: "product"
              },
              _vm._l(item.products, function(info, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "productItem",
                    on: {
                      click: function($event) {
                        return _vm.gotoDetail(info)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "imgBox" }, [
                      _c("img", { attrs: { src: info.image } })
                    ]),
                    _c("div", { staticClass: "desc" }, [
                      _vm._v(_vm._s(info.name))
                    ]),
                    _c("div", { staticClass: "price" }, [
                      _vm._v(_vm._s(info.price ? "￥" + info.price : "-"))
                    ])
                  ]
                )
              }),
              0
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }