var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.isProduct ? "headerTabTwo product" : "headerTabTwo" },
    [
      _vm.TitleName
        ? _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.TitleName))])
        : _vm._e(),
      _vm.Detail
        ? _c("span", { staticClass: "content-text" }, [
            _vm._v(_vm._s(_vm.Detail.content[0]))
          ])
        : _vm._e(),
      _vm._l(_vm.TextContent, function(item) {
        return _vm.TextContent && _vm.TextContent.length > 0
          ? _c("div", { key: item.name }, [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.name))]),
              _c("img", {
                staticClass: "image",
                attrs: {
                  src: _vm.report.solutions.asset_prefix + item.asset,
                  alt: "茶饮图片"
                }
              }),
              item.content.length > 0
                ? _c("span", { staticClass: "tip-one" }, [_vm._v("泡煮方法：")])
                : _vm._e(),
              _c("span", { staticClass: "content-text" }, [
                _vm._v(_vm._s(item.content[0]))
              ]),
              item.content.length > 1
                ? _c("span", { staticClass: "tip-one" }, [_vm._v("功效：")])
                : _vm._e(),
              _c("span", { staticClass: "content-text" }, [
                _vm._v(_vm._s(item.content[1]))
              ])
            ])
          : _vm._e()
      }),
      _vm._l(_vm.PrescriptionContent, function(item, index) {
        return _vm.PrescriptionContent
          ? _c("div", { key: index, staticClass: "prescription" }, [
              _c("span", { staticClass: "teasty" }, [
                _vm._v(_vm._s(item.name))
              ]),
              _c("span", { staticClass: "medicinal" }, [_vm._v("药物组成：")]),
              _c("span", { staticClass: "medicinal-explain" }, [
                _vm._v(_vm._s(item.form))
              ]),
              _c("span", { staticClass: "medicinal" }, [_vm._v("功效：")]),
              _c("span", { staticClass: "medicinal-explain" }, [
                _vm._v(_vm._s(item.effect))
              ])
            ])
          : _vm._e()
      }),
      _vm.PrescriptionContent
        ? _c("span", { staticClass: "tip" }, [
            _vm._v(" *请在医生、药师指导下购买和服用 ")
          ])
        : _vm._e(),
      _vm.exercises
        ? _c(
            "div",
            { style: { marginTop: "50PX" } },
            _vm._l(_vm.exercises.content, function(item, index) {
              return _c("div", { key: index, staticClass: "content-text" }, [
                _vm._v(_vm._s(item))
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.AcupointContent
        ? _c("div", [
            _vm.report.type_attention === "糖尿病"
              ? _c("div", { style: { marginTop: "50PX" } }, [
                  _c("div", { staticClass: "content-text" }, [
                    _vm._v(_vm._s(_vm.AcupointContent[0].content[0]))
                  ]),
                  _c("div", { staticClass: "content-text" }, [
                    _vm._v(_vm._s(_vm.AcupointContent[0].content[1]))
                  ]),
                  _c("div", { staticClass: "content-text" }, [
                    _vm._v(_vm._s(_vm.AcupointContent[0].content[2]))
                  ])
                ])
              : _vm._e(),
            _vm.report.type_attention === "高血压"
              ? _c(
                  "div",
                  _vm._l(_vm.AcupointContent, function(item, index) {
                    return _c("div", { key: index }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _c("img", {
                        staticClass: "image",
                        attrs: {
                          src:
                            _vm.report.solutions.asset_prefix + item.asset[0],
                          alt: "加载失败"
                        }
                      }),
                      _c("div", { staticClass: "tip-one" }, [_vm._v("定位：")]),
                      _c("span", { staticClass: "content-text" }, [
                        _vm._v(_vm._s(item.content[0]))
                      ]),
                      _c("div", { staticClass: "tip-one" }, [
                        _vm._v("按摩方法：")
                      ]),
                      _c("span", { staticClass: "content-text" }, [
                        _vm._v(_vm._s(item.content[1]))
                      ])
                    ])
                  }),
                  0
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._l(_vm.moxibustion, function(item, index) {
        return _c("div", { key: index, style: { marginBottom: "50px" } }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.name))]),
          _c("img", {
            staticClass: "image",
            attrs: {
              src: _vm.report.solutions.asset_prefix + item.asset[0],
              alt: "加载失败"
            }
          }),
          _c("span", { staticClass: "content-text" }, [
            _vm._v(_vm._s(item.content[0]))
          ])
        ])
      }),
      _vm._l(_vm.footBath, function(item, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(item.name))
          ]),
          _c("img", {
            staticClass: "image",
            attrs: {
              src: _vm.report.solutions.asset_prefix + item.asset[0],
              alt: "加载失败"
            }
          }),
          _c("div", { staticClass: "tip-one" }, [
            _vm._v(
              _vm._s(
                _vm.report.type_attention === "糖尿病"
                  ? "方药组成："
                  : "足浴配方："
              )
            )
          ]),
          _c("span", { staticClass: "content-text" }, [
            _vm._v(_vm._s(item.content[0]))
          ]),
          _c("div", { staticClass: "tip-one" }, [
            _vm._v(
              _vm._s(
                _vm.report.type_attention === "糖尿病" ? "适应症：" : "操作："
              )
            )
          ]),
          _c("span", { staticClass: "content-text" }, [
            _vm._v(_vm._s(item.content[1]))
          ]),
          _vm.report.type_attention === "糖尿病"
            ? _c("div", { staticClass: "tip-one" }, [_vm._v("用法用量：")])
            : _vm._e(),
          _vm.report.type_attention === "糖尿病"
            ? _c("span", { staticClass: "content-text" }, [
                _vm._v(_vm._s(item.content[2]))
              ])
            : _vm._e()
        ])
      }),
      _vm.isProduct
        ? _c(
            "div",
            [
              _c("ProductTzbs"),
              _vm.isShowProDetail ? _c("ProductDetail") : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }