var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxpulseresult" }, [
    _c("div", { staticClass: "title" }, [_vm._v("脉诊结果")]),
    _c("img", { attrs: { src: _vm.pulse[0].pulse_image, alt: "脉诊图片" } }),
    _c(
      "div",
      { staticClass: "total" },
      [
        _c("span", { staticClass: "title2" }, [_vm._v("总脉")]),
        _vm._l(_vm.summaryArr, function(item, index) {
          return _c("div", { key: index, staticClass: "tip" }, [
            _vm._v(_vm._s(item))
          ])
        })
      ],
      2
    ),
    _c("span", { staticClass: "title2" }, [_vm._v("心率")]),
    _c("span", { staticClass: "rate" }, [
      _vm._v(_vm._s(_vm.pulse[0].heart_rate) + "次/分")
    ]),
    _c("div", { staticClass: "tip2" }, [
      _vm._v("提示：" + _vm._s(_vm.pulse[0].summary_attention))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }