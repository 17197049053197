var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.productval
    ? _c("div", [
        _c("div", { ref: "top", staticClass: "wares" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", { on: { click: _vm.back } }, [_vm._v("< 返回")]),
            _c("h1", [_vm._v("特别推荐")])
          ]),
          _c(
            "div",
            { staticClass: "proDetail" },
            [
              _c("img", { attrs: { src: _vm.productval.image, alt: "大图" } }),
              _c(
                "div",
                {
                  staticClass: "price",
                  style:
                    this.productval.qr_code || this.productval.market_url
                      ? { width: "75%" }
                      : { width: "90%" }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { overflow: "auto", margin: "0" } },
                    [
                      _c("span", { staticClass: "boxname" }, [
                        _vm._v(
                          _vm._s(
                            _vm.productval.name ? _vm.productval.name : "未知"
                          )
                        )
                      ]),
                      _c("span", { staticClass: "boxprice" }, [
                        _vm._v(
                          _vm._s(
                            _vm.productval.price
                              ? "¥" + _vm.productval.price + " "
                              : ""
                          )
                        )
                      ])
                    ]
                  ),
                  _c("span", { staticClass: "boxdesc" }, [
                    _vm._v(
                      _vm._s(
                        _vm.productval.desc
                          ? _vm.productval.desc
                          : "暂无相关描述"
                      )
                    )
                  ])
                ]
              ),
              _c("div", { staticClass: "buySrc" }, [
                this.productval.qr_code
                  ? _c("img", { attrs: { src: this.productval.qr_code } })
                  : _vm._e(),
                _c("br")
              ]),
              _vm._l(_vm.productval.photos, function(item, index) {
                return _c("img", {
                  key: index,
                  staticClass: "selfImg",
                  attrs: { src: item.image }
                })
              })
            ],
            2
          ),
          _c("footer", [
            _vm._v(
              " 注：特别推荐所展示的商品/服务的标题、价格、详情等信息内容系由合作方发布，其真实性、准确性和合法性由信息拥有者（合作方）负责。本站不提供任何保证，并不承担任何法律责任。 "
            )
          ]),
          _vm.marker !== "10.1"
            ? _c(
                "button",
                {
                  staticClass: "toTop",
                  attrs: { title: "回顶部" },
                  on: { click: _vm.backTop }
                },
                [_vm._v("返回顶部")]
              )
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }