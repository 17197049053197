var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-bg" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "back-btn", on: { click: _vm.goBack } }, [
          _vm._v("返 回")
        ]),
        _c("div", { staticClass: "header-title" }, [_vm._v("养生方案")])
      ]),
      _c("header-tab", {
        attrs: { buttonType: _vm.buttonType },
        on: { changed: _vm.changedIndex }
      }),
      _vm.currentIndex === 0
        ? _c("header-tab-one", { attrs: { isChronic: _vm.isChronic } })
        : _vm._e(),
      _vm.currentIndex === 1
        ? _c("header-tab-two", {
            attrs: { SymptomsName: _vm.symptomsName, TextContent: _vm.tea }
          })
        : _vm._e(),
      _vm.currentIndex === 2
        ? _c("header-tab-two", {
            attrs: {
              filedName: "PrescriptionContent",
              PrescriptionContent: _vm.yf
            }
          })
        : _vm._e(),
      _vm.currentIndex === 3
        ? _c("header-tab-two", { attrs: { exercises: _vm.exercises } })
        : _vm._e(),
      _vm.currentIndex === 4
        ? _c("header-tab-two", { attrs: { AcupointContent: _vm.xw } })
        : _vm._e(),
      _vm.currentIndex === 5
        ? _c("header-tab-two", { attrs: { moxibustion: _vm.moxibustion } })
        : _vm._e(),
      _vm.currentIndex === 6
        ? _c("header-tab-two", { attrs: { footBath: _vm.footBath } })
        : _vm._e(),
      _vm.currentIndex === 7
        ? _c("header-tab-two", {
            attrs: { TitleName: "精神调养", Detail: _vm.mental }
          })
        : _vm._e(),
      _vm.currentIndex === 8
        ? _c("header-tab-two", {
            attrs: { TitleName: "运动保健", Detail: _vm.sportHealth }
          })
        : _vm._e(),
      _vm.currentIndex === 9
        ? _c("header-tab-two", { attrs: { isProduct: true } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }